//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
    name: "SiteNavbar",
    props: {
        activeSite: String
    },
    data: () => {
        return {
            navOpen: false
        }
    }
}
