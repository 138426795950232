import Courtage from '../views/Courtage/Courtage'
import CourtageHome from '../views/Courtage/Home'
import CourtageAgence from '../views/Components/Agence'
import CourtageContact from '../views/Components/Contact'
import Financement from '../views/Courtage/Financement'
import Assurance from '../views/Courtage/Assurance'
import DemandePretForm from '../views/Courtage/DemandePretForm'
import MentionsLegales from '../views/Components/MentionsLegales'
import RecrutementForm from '../views/Components/RecrutementForm'

import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Courtage,
    children: [
      {
        name: 'CourtageHome',
        path: '/',
        component: CourtageHome
      },
      {
        name: 'Financement',
        path: 'Financement',
        component: Financement
      },
      {
        name: 'Assurance',
        path: 'Assurance',
        component: Assurance
      },
      {
        name: 'CourtageAgence',
        path: 'Agence',
        component: CourtageAgence,
        props: { siteCode: 'cg' }
      },
      {
        name: 'CourtageContact',
        path: 'Contact',
        component: CourtageContact,
        props: { siteCode: 'cg' }
      },
      {
        name: 'CGRecrutementForm',
        path: 'Recrutement',
        component: RecrutementForm,
        props: { siteCode: 'cg' }
      },
      {
        name: 'DemandePretForm',
        path: 'Situation',
        component: DemandePretForm
      },
      {
        name: 'MentionsLegales',
        path: 'MentionsLegales',
        component: MentionsLegales,
        props: { siteCode: 'cg' }
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    window.scrollTo(0, 0)
  }
})

export default router