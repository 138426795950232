//
//
//
//
//
//
//
//
//

// @ is an alias to /src
import { getFocus } from '../../mixins/getFocus'

export default {
  name: 'Investir',
  mixins: [getFocus],
  components: {
    ColorPointsHeader: () => import('../../components/ColorPointsHeader')
  },
  data: function () {
    return {
      focus: {},
      pinkHeaderMessage: {
        titre: '',
        sousTitre: ''
      }
    }
  },
  async beforeMount () {
    await this.getFocus('Tolus Immo', 'Investir')
      .then(res => {
        this.focus = res
          this.pinkHeaderMessage.titre = this.focus.cartouche ? this.focus.cartouche.titre : ''
          this.pinkHeaderMessage.sousTitre = this.focus.cartouche ? this.focus.cartouche.texte : ''
      })
  }
}
