//
//
//
//
//
//
//
//

import DataApi from 'fm-data-api'

export default {
  name: 'Focus',
  props: {
    focusRecordId: String
  },
  data () {
    return {
      focus: {}
    }
  },
  async beforeMount () {
    const options = {
      apiUrl: this.$apiUrl,
      databaseName: this.$databaseName,
      token: await this.$getMainToken()
    }
    this.dataApi = new DataApi(options)
    await this.dataApi.getRecord('W_Focus', this.focusRecordId)
      .then((focus) => {
        if (focus[0]) {
          this.focus = focus[0].fieldData
        }
      })
  },
  computed: {
    societyClass: function () {
      if (!this.focus['foc_rbq_SOC__Societe::d_nomSociete']) {
        return ''
      }
      return this.focus['foc_rbq_SOC__Societe::d_nomSociete'] === 'CG Courtage' ? 'primary' : 'secondary'
    }
  }
}
