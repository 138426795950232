//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// @ is an alias to /src
import Vue from 'vue'
import { NavbarPlugin, ButtonPlugin } from 'bootstrap-vue'
Vue.use(NavbarPlugin)
Vue.use(ButtonPlugin)

export default {
  name: 'App',
  data () {
    return {
      isFinancement: false,
      isAssurance: false
    }
  },
  methods: {
    checkLink () {
      switch (this.$router.currentRoute.path) {
        case '/CGC/financement.html':
          this.isFinancement = true
          this.isAssurance = false
          break
        case '/CGC/financementImmobilier.html':
          this.isFinancement = true
          this.isAssurance = false
          break
        case '/CGC/rachatCredits.html':
          this.isFinancement = true
          this.isAssurance = false
          break
        case '/CGC/renegociationPret.html':
          this.isFinancement = true
          this.isAssurance = false
          break
        case '/CGC/pretsProfessionnels.html':
          this.isFinancement = true
          this.isAssurance = false
          break
        case '/CGC/pretsHypotecaires.html':
          this.isFinancement = true
          this.isAssurance = false
          break
        case '/CGC/remere.html':
          this.isFinancement = true
          this.isAssurance = false
          break
        case '/CGC/assurance.html':
          this.isFinancement = false
          this.isAssurance = true
          break
        case '/CGC/assuranceCredit.html':
          this.isFinancement = false
          this.isAssurance = true
          break
        case '/CGC/assuranceRevenus.html':
          this.isFinancement = false
          this.isAssurance = true
          break
        case '/CGC/fraisMedicaux.html':
          this.isFinancement = false
          this.isAssurance = true
          break
        case '/CGC/autoMoto.html':
          this.isFinancement = false
          this.isAssurance = true
          break
        case '/CGC/assuranceBien.html':
          this.isFinancement = false
          this.isAssurance = true
          break
        default:
          this.isFinancement = false
          this.isAssurance = false
      }
    }
  },
  beforeMount () {
    this.checkLink()
  },
  updated () {
    this.checkLink()
  }
}

