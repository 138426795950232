//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DataApi from '../../dependencies/index'
import { getFocus } from '../../mixins/getFocus'


export default {
  name: 'RecrutementForm',
  mixins: [getFocus],
  props: {
    siteCode: String
  },
  data () {
    return {
      fiche: {
        nom: '',
        prenom: '',
        email: '',
        telephone: '',
        adresse: '',
        d_salaire: 0,
        questions: '',
        type: 'recrutement',
        destinataire: this.siteCode
      },
      cv: null,
      formSent: false,
      error: null,
      errorMessage: 'Erreur de communication avec le serveur, veuillez nous excuser pour la gène occasionnée et recommencer plus tard',
      focus: {}
    }
  },
  methods: {
    async addDemande () {
      const demandeToSend = this.fiche
      const options = {
        apiUrl: this.$apiUrl,
        databaseName: this.$databaseName,
        token: await this.$getMainToken()
      }
      this.dataApi = new DataApi(options)
      this.dataApi.createRecord(
        'W_DemandeContact', 
        demandeToSend,
        [{
          name: 'validerFormulaire',
          type: 'postrequest'
        }]
      )
      .then((response) => {
        this.dataApi.uploadToContainer('W_DemandeContact', response, 'd_cv', '', this.cv)
        .then((res) => {
          this.formSent = true
        })
        .catch((err) => {
          this.error = this.errorMessage
        })
      })
      .catch((err) => {
        this.error = this.errorMessage
      })
        
    },
    uploadCv(event) {
      let cvInput = document.getElementById("cv")
      this.cv = cvInput.files ? cvInput.files[0] : ''
      console.log(this.cv)
    }
  },
  async beforeMount() {
    await this.getFocus(this.siteCode, 'Recrutement')
      .then(res => {
        this.focus = res
      })
  }
}
