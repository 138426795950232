//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'NewsletterBar'
}
