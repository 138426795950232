import Patrimoine from '../views/Patrimoine/Patrimoine'
import PatrimoineHome from '../views/Patrimoine/Home'
import PatrimoineAgence from '../views/Components/Agence'
import PatrimoineContact from '../views/Components/Contact'
import Ingenierie from '../views/Patrimoine/Ingenierie'
import Epargne from '../views/Patrimoine/Epargne'
import EtudePatrimonialeForm from '../views/Patrimoine/EtudePatrimonialeForm'
import RecrutementForm from '../views/Components/RecrutementForm'
import GPMentionsLegales from '../views/Components/MentionsLegales'

import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Patrimoine,
    children: [
      {
        name: 'PatrimoineHome',
        path: '/',
        component: PatrimoineHome
      },
      {
        name: 'Ingenierie',
        path: 'Ingenierie',
        component: Ingenierie
      },
      {
        name: 'Epargne',
        path: 'Epargne',
        component: Epargne
      },
      {
        name: 'PatrimoineAgence',
        path: 'Agence',
        component: PatrimoineAgence,
        props: { siteCode: 'gp' }
      },
      {
        name: 'PatrimoineContact',
        path: 'Contact',
        component: PatrimoineContact,
        props: { siteCode: 'gp' }
      },
      {
        name: 'GPRecrutementForm',
        path: 'Recrutement',
        component: RecrutementForm,
        props: { siteCode: 'gp' }
      },
      {
        name: 'EtudePatrimonialeForm',
        path: 'Situation',
        component: EtudePatrimonialeForm
      },
      {
        name: 'RecrutementForm',
        path: 'Recrutement',
        component: RecrutementForm
      },
      {
        name: 'GPMentionsLegales',
        path: 'MentionsLegales',
        component: GPMentionsLegales,
        props: { siteCode: 'gp' }
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    window.scrollTo(0, 0)
  }
})

export default router