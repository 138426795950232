//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Vue from 'vue'
import DataApi from 'fm-data-api'
import { FormGroupPlugin, FormPlugin, FormInputPlugin, FormTextareaPlugin, FormCheckboxPlugin, InputGroupPlugin, ButtonPlugin } from 'bootstrap-vue'
import lozad from 'lozad'
import { getFocus } from '../../mixins/getFocus'

Vue.use(FormGroupPlugin)
Vue.use(FormPlugin)
Vue.use(FormInputPlugin)
Vue.use(FormTextareaPlugin)
Vue.use(FormCheckboxPlugin)
Vue.use(InputGroupPlugin)
Vue.use(ButtonPlugin)

const observer = lozad() // lazy loads elements with default selector as '.lozad'
observer.observe()

export default {
  name: 'Achat',
  mixins: [getFocus],
  components: {
    ColorPointsHeader: () => import('../../components/ColorPointsHeader'),
    AutoCompleteGouvApi: () => import('../../components/AutoCompleteGouvApi')
  },
  data () {
    return {
      display: false,
      pinkHeaderMessage: {
        titre: '',
        sousTitre: ''
      },
      cgvText: '',
      paragraphe1: '',
      srcGraphique: require('../../assets/images/graphique-page-vente@2x.jpg'),
      civilitesOptions: [
        {
          id: 1,
          label: 'Madame',
          value: 'Madame'
        },
        {
          id: 2,
          label: 'Monsieur',
          value: 'Monsieur'
        }
      ],
      typeBienOptions: [
        { id: 1, value: 'Maison / Villa', label: 'Maison / Villa' },
        { id: 2, value: 'Appartement', label: 'Appartement' },
        { id: 3, value: 'Terrain', label: 'Terrain' },
        { id: 4, value: 'Commerce', label: 'Commerce' },
        { id: 5, value: 'Bureau / Local commercial', label: 'Bureau / Local commercial' },
        { id: 6, value: 'Parking', label: 'Parking' },
        { id: 7, value: 'Immeuble', label: 'Immeuble' },
        { id: 8, value: 'Hangar', label: 'Hangar' },
        { id: 9, value: 'Hôtel particulier', label: 'Hôtel particulier' },
        { id: 10, value: 'Droit au bail', label: 'Droit au bail' },
        { id: 11, value: 'Divers', label: 'Divers' }
      ],
      secteurOptions: [
        { id: 1, label: 'Centre-ville', value: 'Centre-ville' },
        { id: 2, label: 'Périphérie', value: 'Péripherie' }
      ],
      fiche: {
        civilite: 'Madame',
        nom: null,
        prenom: null,
        email: null,
        telephone: '',
        type: 'Maison / Villa',
        secteur: 'Centre-ville',
        lieu: null,
        surface: null,
        surfaceHabitable: null,
        surfaceTerrain: null,
        prix: null,
        description: null,
        rgpd: null
      },
      error: {
        nom: null,
        prenom: null,
        type: null,
        secteur: null,
        lieu: null,
        surface: null,
        surfaceHabitable: null,
        surfaceTerrain: null,
        prix: null,
        description: null,
        rgpd: null,
        email: null
      },
      formSent: false,
      /* message d'erreur affiché pour un champs requis vide */
      errorMessage: 'Ce champs est requis',
      focus: {}
    }
  },
  methods: {
    changeGPSData (centre) {
      this.fiche.lieu = centre.commune
    },
    checkErrors () {
      this.error.civilite = (!!this.fiche.civilite)
      this.error.type = (!!this.fiche.type)
      this.error.nom = (!!this.fiche.nom)
      this.error.prenom = (!!this.fiche.prenom)
      this.error.secteur = (!!this.fiche.secteur)
      this.error.email = (!!this.fiche.email)
      this.error.lieu = (!!this.fiche.lieu)
      this.error.surface = (!!this.fiche.surface)
      this.error.surfaceHabitable = (!!this.fiche.surfaceHabitable)
      this.error.surfaceTerrain = (!!this.fiche.surfaceTerrain)
      this.error.prix = (!!this.fiche.prix)
      this.error.description = (!!this.fiche.description)
      this.error.rgpd = (!!this.fiche.rgpd && this.fiche.rgpd !== '0')
      let stop = false
      Object.entries(this.error).forEach(([key, value]) => {
        if (value === false) {
          console.log('error : ' + key)
          stop = true
        }
      })
      if (stop) {
        setTimeout(() => {
          Object.keys(this.error).forEach((key) => {
            this.error[key] = null
          })
        }, 3000)
      } else {
        this.addFiche()
      }
    },
    async addFiche () {
      const bienToSend = this.fiche
      const options = {
        apiUrl: this.$apiUrl,
        databaseName: this.$databaseName,
        token: await this.$getMainToken()
      }
      this.dataApi = new DataApi(options)
      this.dataApi.createRecord('W_BienAEvaluer', bienToSend)
        .then((response) => {
          console.log(response)
          this.formSent = true
        })
    },
    myEventHandler () {
      if (window.innerWidth < 767) {
        this.srcGraphique = require('../../assets/images/graphique-page-vente.jpg')
      } else {
        this.srcGraphique = require('../../assets/images/graphique-page-vente@2x.jpg')
      }
      observer.observe()
    }
  },
  created () {
    if (window.innerWidth < 767) {
      this.srcGraphique = require('../../assets/images/graphique-page-vente.jpg')
    }
    window.addEventListener('resize', this.myEventHandler)
  },
  mounted () {
    const observer = lozad()
    observer.observe()
    this.display = true
  },
  async beforeMount () {
    await this.getFocus('Tolus Immo', 'Vente')
      .then(res => {
        this.focus = res
          this.pinkHeaderMessage.titre = this.focus.cartouche ? this.focus.cartouche.titre : ''
          this.pinkHeaderMessage.sousTitre = this.focus.cartouche ? this.focus.cartouche.texte : ''
      })
  }
}
