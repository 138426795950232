import Immobilier from '../views/Immobilier/Immobilier'
import ImmoHome from '../views/Immobilier/Home'
import AdList from '../views/Immobilier/AdList'
import AdDetails from '../views/Immobilier/AdDetails'
import Sell from '../views/Immobilier/Sell'
import ImmoAgence from '../views/Components/Agence'
import ImmoContact from '../views/Components/Contact'
import MentionsLegales from '../views/Components/MentionsLegales'
import BaremeHonoraire from '../views/Immobilier/BaremeHonoraire'
import RecrutementForm from '../views/Components/RecrutementForm'

import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Immobilier,
    children: [
      {
        name: 'ImmoHome',
        path: '/',
        component: ImmoHome
      },
      {
        name: 'AdList',
        path: 'Resultats',
        component: AdList
      },
      {
        name: 'AdDetails',
        path: 'Resultats/:recordId',
        component: AdDetails,
        props: true
      },
      {
        name: 'Sell',
        path: 'Vendre',
        component: Sell
      },
      {
        name: 'ImmoAgence',
        path: 'Agence',
        component: ImmoAgence,
        props: { siteCode: 'ti' }
      },
      {
        name: 'ImmoContact',
        path: 'Contact',
        component: ImmoContact,
        props: { siteCode: 'ti' }
      },
      {
        name: 'TIRecrutementForm',
        path: 'Recrutement',
        component: RecrutementForm,
        props: { siteCode: 'ti' }
      },
      {
        name: 'ImmoMentionsLegales',
        path: 'MentionsLegales',
        component: MentionsLegales,
        props: { siteCode: 'ti' }
      },
      {
        name: 'BaremeHonoraire',
        path: 'BaremeHonoraire',
        component: BaremeHonoraire
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    window.scrollTo(0, 0)
  }
})

export default router