//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DataApi from 'fm-data-api'
import lozad from 'lozad'
import { getFocus } from '../../mixins/getFocus'

const observer = lozad() // lazy loads elements with default selector as '.lozad'
observer.observe()

export default {
  name: 'Sell',
  mixins: [getFocus],
  components: {
    AutoCompleteGouvApi: () => import('../../components/AutoCompleteGouvApi')
  },
  data() {
    return {
      civilitesOptions: [
        {
          id: 1,
          label: 'Madame',
          value: 'Madame'
        },
        {
          id: 2,
          label: 'Monsieur',
          value: 'Monsieur'
        }
      ],
      typeBienOptions: [
        { id: 1, value: 'Maison / Villa', label: 'Maison / Villa' },
        { id: 2, value: 'Appartement', label: 'Appartement' },
        { id: 3, value: 'Terrain', label: 'Terrain' },
        { id: 4, value: 'Commerce', label: 'Commerce' },
        { id: 5, value: 'Bureau / Local commercial', label: 'Bureau / Local commercial' },
        { id: 6, value: 'Parking', label: 'Parking' },
        { id: 7, value: 'Immeuble', label: 'Immeuble' },
        { id: 8, value: 'Hangar', label: 'Hangar' },
        { id: 9, value: 'Hôtel particulier', label: 'Hôtel particulier' },
        { id: 10, value: 'Droit au bail', label: 'Droit au bail' },
        { id: 11, value: 'Divers', label: 'Divers' }
      ],
      secteurOptions: [
        { id: 1, label: 'Centre-ville', value: 'Centre-ville' },
        { id: 2, label: 'Périphérie', value: 'Péripherie' }
      ],
      fiche: {
        civilite: 'Madame',
        nom: null,
        prenom: null,
        email: null,
        telephone: '',
        type: 'Maison / Villa',
        secteur: 'Centre-ville',
        surface: null,
        surfaceHabitable: null,
        surfaceTerrain: null,
        prix: null,
        description: null,
        rgpd: null
      },
      formSent: false,
      focus: {}
    }
  },
  methods: {
    changeGPSData(centre) {
      this.fiche.lieu = centre.commune
    },
    async sendData() {
      const bienToSend = this.fiche
      const options = {
        apiUrl: this.$apiUrl,
        databaseName: this.$databaseName,
        token: await this.$getMainToken()
      }
      this.dataApi = new DataApi(options)
      this.dataApi.createRecord('W_BienAEvaluer', bienToSend)
        .then((response) => {
          console.log(response)
          this.formSent = true
        })
    },
  },
  async beforeMount() {
    await this.getFocus('ti', 'Vendre')
      .then(res => {
        this.focus = res
      })
  }
}

