//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// @ is an alias to /src

import { getFocus } from '../../mixins/getFocus'

export default {
  name: 'IngenierieConseilPatrimoniaux',
  mixins: [getFocus],
  components: {
    ColorPointsHeader: () => import('../../components/ColorPointsHeader')
  },
  data () {
    return {
      greenHeaderMessage: {
        titre: '',
        sousTitre: ''
      },
      temoignages: {},
      focus: [],
      bulles: []
    }
  },
  async beforeMount () {
    await this.getFocus('CG Courtage', 'Ingénierie et conseils patrimoniaux')
      .then(res => {
        this.focus = res
        this.greenHeaderMessage.titre = this.focus.cartouche ? this.focus.cartouche.titre : ''
        this.greenHeaderMessage.sousTitre = this.focus.cartouche ? this.focus.cartouche.texte : ''
        this.bulles = Object.keys(this.focus).filter((key) => {
          return key.substring(0, 5) === 'bulle'
        })
      })
  }
}
