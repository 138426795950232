//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Vue from 'vue'
import DataApi from 'fm-data-api'
import { NavbarPlugin, FormGroupPlugin, FormPlugin, FormInputPlugin, FormTextareaPlugin, FormCheckboxPlugin, InputGroupPlugin, ButtonPlugin, AlertPlugin } from 'bootstrap-vue'

Vue.use(NavbarPlugin)
Vue.use(FormGroupPlugin)
Vue.use(FormPlugin)
Vue.use(FormInputPlugin)
Vue.use(FormTextareaPlugin)
Vue.use(FormCheckboxPlugin)
Vue.use(InputGroupPlugin)
Vue.use(ButtonPlugin)
Vue.use(AlertPlugin)

export default {
  name: 'Compte',
  data () {
    return {
      dataApi: {},
      identifiant: null,
      motDePasse: null,
      rememberMe: null,
      error: {
        identifiant: null,
        motDePasse: null
      },
      dismissSecs: 5,
      dismissCountDown: 0,
      errorMessage: 'Ce champs est requis'
    }
  },
  methods: {
    checkErrors () {
      this.error.identifiant = (!!this.identifiant)
      this.error.motDePasse = (!!this.motDePasse)
      let stop = false
      Object.values(this.error).forEach((value) => {
        if (value === false) {
          stop = true
        }
      })
      if (stop) {
        setTimeout(() => {
          Object.keys(this.error).forEach((key) => {
            this.error[key] = null
          })
        }, 3000)
      } else {
        this.connect()
      }
    },
    async connect () {
      const options = {
        apiUrl: this.$apiUrl,
        databaseName: this.$databaseName,
        login: this.identifiant,
        password: this.motDePasse
      }
      this.dataApi = new DataApi(options, false)
      this.dataApi.login()
        .then((res) => {
          this.dataApi = res
          this.dataApi.validateSession()
            .then((response) => {
              if (response) {
                Vue.prototype.$token = this.dataApi.apiToken
                Vue.prototype.$isLogged = true
                this.$router.push({ name: 'TableauDeBord' })
              } else {
                this.showAlert()
              }
            })
        })
    },
    countDownChanged (dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    showAlert () {
      this.error.identifiant = false
      this.error.motDePasse = false
      this.dismissCountDown = this.dismissSecs
      setTimeout(() => {
        Object.keys(this.error).forEach((key) => {
          this.error[key] = null
        })
      }, 3000)
    }
  }
}
