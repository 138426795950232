import Vue from 'vue'

export const FILTERS = {
  camelCase: (value) => {
    const b = 'áàâäãåçéèêëíïîìñóòôöõúùûüýÁÀÂÄÃÅÇÉÈÊËÍÏÎÌÑÓÒÔÖÕÚÙÛÜÝ'
    const c = 'aaaaaaceeeeiiiinooooouuuuyAAAAAACEEEEIIIINOOOOOUUUUY'
    let d = ''
    for (let i = 0, j = value.length; i < j; i++) {
      const e = value.substr(i, 1)
      d += (b.indexOf(e) !== -1) ? c.substr(b.indexOf(e), 1) : e
    }
    return d.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase()
    }).replace(/\s+/g, '').replace(/\//g, '')
  },
  capitalize: (value) => {
    if (!value) return ''
    value = value.toString()
    return value.charAt(0).toUpperCase() + value.slice(1)
  },
  truncate: (string, length) => {
    return string.slice(0, length) + '...'
  },
  formatMoney: (value) => {
    const toReturn = parseInt(value).toLocaleString('fr-FR')
    return toReturn
  }
}

export const registerFilters = () => {
  Object.keys(FILTERS).forEach(key => {
    Vue.filter(key, FILTERS[key])
  })
}
