//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Vue from 'vue'
import DataApi from 'fm-data-api'
import { ButtonPlugin, ModalPlugin } from 'bootstrap-vue'
import { getImage } from '../../mixins/getImage'
import DPE from '../../components/DPE.vue'

Vue.use(ButtonPlugin)
Vue.use(ModalPlugin)

export default {
    name: 'AdDetails',
    components: {
        Simulateur: () => import("../../components/Simulateur"),
        PictoArrow: () => import("../../components/PictoArrow"),
        PictoNetwork: () => import("../../components/PictoNetwork"),
        PictoPrint: () => import("../../components/PictoPrint"),
        AdPreview: () => import("./AdPreviewCard.vue"),
        BienPhotosGallery: () => import("../../components/BienPhotosGallery.vue"),
        BienAlertEmail: () => import("../../components/BienAlertEmail.vue"),
        DPE
    },
    mixins: [getImage],
    data() {
        return {
            ad: {
                d_type: null,
                d_reference: null,
                d_price: null,
                d_titre: null,
                d_nbrPieces: null,
                d_surface: null,
                d_descriptif: null
            },
            d_image: '',
            photos: [],
            adsAnnexes: {},
            societe: null,
            contactInfo: {
                nom: '',
                email: '',
                telephone: '',
                questions: '',
                type: 'interet',
                destinataire: 'ti'
            },
            formSent: false
        }
    },
    props: {
        recordId: String
    },
    methods: {
        async loadBien() {
            const options = {
                apiUrl: this.$apiUrl,
                databaseName: this.$databaseName,
                token: await this.$getMainToken()
            }
            const dataApi = new DataApi(options)
            dataApi.getRecord('W_AnnonceDetail', this.recordId, [{ name: 'photos' }])
                .then((response) => {
                    this.ad = response[0].fieldData
                    const adPhotosL = JSON.parse(JSON.stringify(response[0].portalData[Object.keys(response[0].portalData)]))
                    console.log(adPhotosL.length)
                    if (adPhotosL.length > 0) {
                        this.insertBienPhotos(adPhotosL)
                    }
                    const query = this.buildRequest()
                    const sort = [
                        {
                            fieldName: 'd_prix',
                            sortOrder: 'descend'
                        },
                        {
                            fieldName: 'd_dateAnnonce',
                            sortOrder: 'descend'
                        }
                    ]
                    dataApi.findRecords('W_Annonce', query, sort, '', '3')
                        .then((response) => {
                            this.insertBiensAnnexes(response)
                        })
                })
        },
        buildRequest() {
            const nbrPieces = parseInt(this.ad.d_nbrPieces * 0.8)
            const surface = parseInt(this.ad.d_surface * 0.8)
            const budget = parseInt(this.ad.d_prix * 0.8)
            const arrayFields = [
                { fieldname: 'd_type', fieldvalue: `==${this.ad.d_type}` },
                { fieldname: 'd_nbrPieces', fieldvalue: `>=${nbrPieces}` },
                { fieldname: 'd_surface', fieldvalue: `>=${surface}` },
                { fieldname: 'd_prix', fieldvalue: `>=${budget}` }
            ]
            const query = []
            query.push({ fields: arrayFields })
            return query
        },
        insertBienPhotos(adPhotosL) {
            this.photos = []
            adPhotosL.forEach(function (value, index) {
                const photo = Vue.removeLayout(value)
                this.getImage(photo.recordId)
                    .then(res => {
                        const ph = {}
                        ph.d_image = res
                        ph.d_imageAlt = photo.d_imageAlt
                        this.photos.push(ph)
                    })
            }, this)
        },
        insertBiensAnnexes(adsAnnexesL) {
            const adsAnnexesList = (adsAnnexesL === undefined) ? [] : adsAnnexesL
            if (adsAnnexesList.length > 0) {
                const adsToInsert = {}
                adsAnnexesList.forEach(function (value, index) {
                    const dataToInsert = value.fieldData
                    dataToInsert.recordId = value.recordId
                    adsToInsert[index] = dataToInsert
                })
                this.adsAnnexes = adsToInsert
            } else {
                this.adsAnnexes = {}
            }
        },
        imprimer() {
            window.print()
        },
        async sendInterest () {
            console.log('send')
            const demandeToSend = this.contactInfo
            demandeToSend.reference = this.ad.d_reference
            console.log(demandeToSend)
            const options = {
                apiUrl: this.$apiUrl,
                databaseName: this.$databaseName,
                token: await this.$getMainToken()
            }
            this.dataApi = new DataApi(options)
            this.dataApi.createRecord(
                'W_DemandeContact', 
                demandeToSend
            )
            .then((res) => {
                this.formSent = true
            })
        }
    },
    watch: {
        // call the method if the route changes
        $route: {
            handler: 'loadBien',
            immediate: true // runs immediately with mount() instead of calling method on mount hook
        }
    },
    async beforeMount() {
        const options = {
            apiUrl: this.$apiUrl,
            databaseName: this.$databaseName,
            token: await this.$getMainToken()
        }
        this.dataApi = new DataApi(options)
        this.dataApi.findRecords('W_Societe', { 'd_codeSociete': 'ti' }, '', '', '')
        .then((response) => {
            this.societe = response[0] ? response[0]['fieldData'] : null
        })
    }
}
