//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DataApi from '../../dependencies/index'
import Vue from 'vue'
import Element from 'element-ui'

Vue.use(Element)
export default {
    name: 'AdSearchbar',
    components: {
        MultipleAutoComplete: () => import('../../components/MultipleAutoComplete'),
    },
    props: {
        fullSize: {
            type: Boolean
        },
        adSearchModelProp: {
            type: Object,
            default: {
                type: null,
                roomCount: null,
                surface: null,
                coordinates: null,
                distance: '20',
                budget: null
            }
        }
    },
    emits: ['submit'],
    data() {
        return {
            priceMin: 0,
            priceMax: 1500000,
            surfaceMin: 0,
            surfaceMax: 800,
            adSearchModel: this.adSearchModelProp,
            adTypeOptions: [
                { value: 'Maison / Villa', label: 'Maison / Villa' },
                { value: 'Appartement', label: 'Appartement' },
                { value: 'Terrain', label: 'Terrain' },
                { value: 'Commerce', label: 'Commerce' },
                { value: 'Bureau / Local commercial', label: 'Bureau / Local commercial' },
                { value: 'Parking', label: 'Parking' },
                { value: 'Immeuble', label: 'Immeuble' },
                { value: 'Hangar', label: 'Hangar' },
                { value: 'Hôtel particulier', label: 'Hôtel particulier' },
                { value: 'Droit au bail', label: 'Droit au bail' },
                { value: 'Divers', label: 'Divers' }
            ],
            roomCountOptions: [
                { value: '1', label: '1' },
                { value: '2', label: '2' },
                { value: '3', label: '3' },
                { value: '4', label: '4' },
                { value: '5', label: '5' },
                { value: '6', label: '6' },
                { value: '7', label: '7' },
                { value: '8', label: '8' }
            ],
            distanceOptions: [
                { value: '5', label: '5' },
                { value: '10', label: '10' },
                { value: '20', label: '20' },
                { value: '50', label: '50' }
            ],
            showAlertModal: false,
            alertEmailMessage: null,
            emailAlertLoading: false
        }
    },
    methods: {
        changeGPSData(coordinates, cityNames) {
            this.adSearchModel.coordinates = coordinates
            this.adSearchModel.cityNames = cityNames
        },
        adSearchSubmit() {
            let searchData = { ...this.adSearchModel }
            if (this.adSearchModel.budget && this.adSearchModel.budget[0] === this.priceMin
                && this.adSearchModel.budget[1] === this.priceMax) {
                searchData.budget = null
            }
            if (this.adSearchModel.surface && this.adSearchModel.surface[0] === this.surfaceMin
                && this.adSearchModel.surface[1] === this.surfaceMax) {
                searchData.surface = null
            }
            this.$emit('submit', searchData)
        },
        async createAlert() {
            if (!document.getElementById('email').reportValidity()) {
                return
            }
            this.emailAlertLoading = true
            let searchData = { ...this.adSearchModel }
            console.log(searchData)
            const options = {
                apiUrl: this.$apiUrl,
                databaseName: this.$databaseName,
                token: await this.$getMainToken()
            }
            const request = 'type: ' + searchData.type +
                ' nbrPieces: ' + searchData.roomCount +
                ' surface: ' + searchData.surface +
                ' rayon: ' + searchData.distance +
                ' budget: ' + searchData.budget +
                ' villes: ' + searchData.cityNames
            const demandeToSend = {
                email: this.email,
                telephone: '',
                adresse: '',
                codePostal: '',
                ville: '',
                pays: '',
                questions: request,
                rgpd: '',
                type: 'alerte',
                destinataire: 'ti'
            }
            const dataApi = new DataApi(options)
            dataApi.createRecord('W_DemandeContact', demandeToSend,
                [{
                    name: 'validerFormulaire',
                    type: 'postrequest'
                }])
                .then((response) => {
                    this.emailAlertLoading = false
                    this.emailAlertMessage = 'La demande a bien été envoyée.'
                    setTimeout(() => {
                        this.showAlertModal = false
                        this.emailAlertMessage = null
                    }, 5000)
                })
                .catch((error) => {
                    this.emailAlertLoading = false
                    console.log(error)
                    this.emailAlertMessage = 'La demande n\'a pas pu être enregistrée, veuillez reéssayer plus tard'
                    setTimeout(() => {
                        this.showAlertModal = false
                        this.emailAlertMessage = null
                    }, 5000)
                })

        }
    },
    computed: {
        searchbarSize() {
            return this.fullSize ? 'ad-search-form-full' : 'ad-search-form-small'
        }
    }
}
