import DataApi from 'fm-data-api'

export const getFocus = {
  data: function () {
    return {
      focus: []
    }
  },
  methods: {
    getFocus (societe, rubrique) {
      return this.$getMainToken().then((res) => {
        const options = {
          apiUrl: this.$apiUrl,
          databaseName: this.$databaseName,
          token: res
        }
        this.dataApi = new DataApi(options)
        return new Promise((resolve, reject) => {
          this.dataApi.findRecords('W_Focus', { 'foc_RBQ__Rubrique::d_nomRubrique': rubrique, 'foc_rbq_SOC__Societe::d_codeSociete': societe }, '', '', '')
          .then((focusList) => {
            const focusResponse = {}
            focusList.forEach(function (focus) {
              focusResponse[focus.fieldData.d_type] = {
                titre: focus.fieldData.d_titre,
                texte: focus.fieldData.d_texte,
                theme: focus.fieldData.d_theme,
                image: focus.fieldData['foc_PHOP__PhotoPrincipale::d_image']
              }
            })
            resolve(focusResponse)
          })
        })
      })
    }
  }
}
