//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DataApi from '../../dependencies/index'
import moment from 'moment'

export default {
  name: 'DemandePretForm',
  components: {
    OneToManyFormInput: () => import('../../components/OneToManyFormInput')
  },
  data () {
    return {
      submitResponse: '',
      currentStep: 1,
      model: {
        'cgf_PERP__Personne_estPrincipal::d_situationFamiliale': '',
        'cgf_PERP__Personne_estPrincipal::d_prenom': '',
        'cgf_PERP__Personne_estPrincipal::d_nom': '',
        'cgf_perp_LIEC__Lien_estCouple::d_date': '',
        'cgf_PERP__Personne_estPrincipal::d_naissance': '',
        'cgf_perp_CTCA__Contact_estAdresse::d_chaineDeCaracteres': '',
        'cgf_perp_CTCM__Contact_estMail::d_chaineDeCaracteres': '',
        'cgf_PERP__Personne_estPrincipal::d_civilite': '',
        'cgf_PERP__Personne_estPrincipal::b_personneMorale': '',
        'cgf_PERP__Personne_estPrincipal::d_nomJeuneFille': '',
        'cgf_perp_ACT__Activite::d_intitule': '',
        'cgf_perp_ACT__Activite::d_statut': '',
        'cgf_perp_act_VLRR__Valeur_estRevenu::d_valeurNet': '',
        'cgf_perp_VLRC__Valeur_estImpotsRevenuN_1::d_valeur': '',
        'cgf_perp_VLRC__Valeur_estRevenuFiscalN_1::d_valeur': '',
        'cgf_perp_VLRC__Valeur_estRevenuFiscalN_2::d_valeur': '',
        'cgf_perp_VLRC__Valeur_estTaxeFonciereN_1::d_valeur': '',
        'cgf_perp_scr_prdp_VLRA__Valeur_estApport::d_valeur': '',
        'cgf_perp_scr_PRDP__Produit_estPret::d_duree': '',
        'cgf_perp_scr_prdp_VLRM__Valeur_estMensualite::d_valeur': '',
        'cgf_perp_scr_PRDP__Produit_estPret::d_particularite': '',
        'b_demandePret': 1,
        'cgf_perp_liece_PER__Personne::d_civilite': '',
        'cgf_perp_liece_PER__Personne::b_personneMorale': '',
        'cgf_perp_liece_PER__Personne::d_prenom': '',
        'cgf_perp_liece_PER__Personne::d_nom': '',
        'cgf_perp_liece_PER__Personne::d_nomJeuneFille': ''
      },
      portalData: {
        cgf_perp_liece_PER__Personne: [],
        cgf_perp_CTCF__Contact_estTelFixe: [],
        cgf_perp_CTCP__Contact_estTelMobile: [],
        cgf_perp_lie_PERE__PersonneEnfant: [],
        cgf_perp_DETR__Detenir_estRapport: [],
        cgf_perp_VLRC__Valeur_estCharge: [],
        cgf_perp_scr_prdp_PST__Poste: [],
        coemprunteurImpot: []
      },
      steps: [
        {
          number: 1,
          groups: [
            {
              label: 'Emprunteur principal',
              fields: [],
              size: 'col-sm-12'
            },
            {
              label: 'Identité et Adresse',
              fields: [
                {
                  key: 'cgf_PERP__Personne_estPrincipal::d_civilite',
                  label: 'Titre',
                  type: 'select',
                  options: ['Mme', 'M']
                }, {
                  key: 'cgf_PERP__Personne_estPrincipal::b_personneMorale',
                  label: 'Pour le compte d\'une personne morale',
                  type: 'checkbox'
                }, {
                  key: 'cgf_PERP__Personne_estPrincipal::d_prenom',
                  label: 'Prenom',
                  type: 'text',
                  required: true,
                  value: ''
                }, {
                  key: 'cgf_PERP__Personne_estPrincipal::d_nom',
                  label: 'Nom',
                  type: 'text',
                  required: true
                }, {
                  key: 'cgf_PERP__Personne_estPrincipal::d_nomJeuneFille',
                  label: 'Nom de jeune fille',
                  type: 'text'
                }, {
                  key: 'cgf_perp_CTCA__Contact_estAdresse::d_chaineDeCaracteres',
                  label: 'Adresse',
                  type: 'text'
                }, {
                  key: 'cgf_perp_CTCF__Contact_estTelFixe::d_chaineDeCaracteres',
                  label: 'Tel Fixe',
                  type: 'tel',
                  required: true,
                  subtext: 'Au moins un numéro requis'
                }, {
                  key: 'cgf_perp_CTCP__Contact_estTelMobile::d_chaineDeCaracteres',
                  label: 'Tel Mobile',
                  type: 'tel',
                  required: true,
                  subtext: 'Au moins un numéro requis'
                }, {
                  key: 'cgf_perp_CTCM__Contact_estMail::d_chaineDeCaracteres',
                  label: 'Email',
                  type: 'email'
                }
              ]
            },
            {
              label: 'Naissance et Situation Familiale',
              fields: [
                {
                  key: 'cgf_PERP__Personne_estPrincipal::d_naissance',
                  label: 'Date de naissance',
                  type: 'date',
                  required: true
                }, {
                  key: 'cgf_PERP__Personne_estPrincipal::d_situationFamiliale',
                  label: 'Situation Familiale',
                  type: 'select',
                  required: true,
                  options: [
                    'Marié', 'PACS', 'Célibataire', 'Divorcé', 'Veuf', 'Union Libre'
                  ]
                }, {
                  key: 'cgf_perp_LIEC__Lien_estCouple::d_date',
                  label: 'Date de Mariage ou PACS',
                  type: 'date',
                  required: true,
                  condition: ['d_situationFamiliale', '==', '"Marié" || "PACS"']
                }, {
                  key: 'cgf_perp_lie_PERE__PersonneEnfant',
                  type: 'OneToManyFormInput',
                  label: 'Enfants',
                  headers: [
                    {
                      name: 'Date de naissance',
                      key: 'cgf_perp_lie_PERE__PersonneEnfant::d_naissance',
                      type: 'date'
                    }
                  ]
                }
              ]
            },
            {
              label: 'Travail',
              fields: [
                {
                  key: 'cgf_perp_ACT__Activite::d_intitule',
                  label: 'Profession',
                  type: 'text',
                  required: true
                }, {
                  key: 'cgf_perp_ACT__Activite::d_statut',
                  label: 'Statut',
                  type: 'text'
                }, {
                  key: 'cgf_perp_act_VLRR__Valeur_estRevenu::d_valeurNet',
                  label: 'Revenus nets mensuels',
                  type: 'text',
                  required: true
                }

              ]
            }, 
            {
              label: 'Co-Emprunteurs',
              fields: [],
              size: 'col-sm-12'
            },
            {
              label: 'Identité et Adresse',
              fields: [
                {
                  key: 'cgf_perp_liece_PER__Personne::d_civilite',
                  label: 'Titre',
                  type: 'select',
                  options: ['Mme', 'M']
                }, {
                  key: 'cgf_perp_liece_PER__Personne::b_personneMorale',
                  label: 'Pour le compte d\'une personne morale',
                  type: 'checkbox'
                }, {
                  key: 'cgf_perp_liece_PER__Personne::d_prenom',
                  label: 'Prenom',
                  type: 'text',
                  value: ''
                }, {
                  key: 'cgf_perp_liece_PER__Personne::d_nom',
                  label: 'Nom',
                  type: 'text'
                }, {
                  key: 'cgf_perp_liece_PER__Personne::d_nomJeuneFille',
                  label: 'Nom de jeune fille',
                  type: 'text'
                }, {
                  key: 'cgf_perp_liece_per_CTCA__Contact_estAdresse::d_chaineDeCaracteres',
                  label: 'Adresse',
                  type: 'text'
                }, {
                  key: 'cgf_perp_liece_per_CTCA__Contact_estTelFixe::d_chaineDeCaracteres',
                  label: 'Tel Fixe',
                  type: 'tel',
                  subtext: 'Au moins un numéro requis'
                }, {
                  key: 'cgf_perp_liece_per_CTCA__Contact_estTelMobile::d_chaineDeCaracteres',
                  label: 'Tel Mobile',
                  type: 'tel',
                  subtext: 'Au moins un numéro requis'
                }, {
                  key: 'cgf_perp_liece_per_CTCA__Contact_estMail::d_chaineDeCaracteres',
                  label: 'Email',
                  type: 'email'
                }
              ]
            },
            {
              label: 'Naissance et Situation Familiale',
              fields: [
                {
                  key: 'cgf_perp_liece_PER__Personne::d_naissance',
                  label: 'Date de naissance',
                  type: 'date',
                }, {
                  key: 'cgf_perp_liece_PER__Personne::d_situationFamiliale',
                  label: 'Situation Familiale',
                  type: 'select',
                  options: [
                    'Marié', 'PACS', 'Célibataire', 'Divorcé', 'Veuf', 'Union Libre'
                  ]
                }
              ]
            },
            {
              label: 'Travail',
              fields: [
                {
                  key: 'cgf_perp_liece_per_ACT__Activite::d_intitule',
                  label: 'Profession',
                  type: 'text'
                }, {
                  key: 'cgf_perp_liece_per_ACT__Activite::d_statut',
                  label: 'Statut',
                  type: 'text'
                }, {
                  key: 'cgf_perp_liece_per_act_VLRR__Valeur_estRevenu::d_valeurNet',
                  label: 'Revenus nets mensuels',
                  type: 'text'
                }

              ]
            }
          ]
        },
        {
          number: 2,
          groups: [
            {
              label: 'Votre patrimoine',
              fields: [
                {
                  key: 'cgf_perp_DETR__Detenir_estRapport',
                  type: 'OneToManyFormInput',
                  headers: [
                    {
                      name: 'Date d\'acquisition',
                      type: 'date',
                      key: 'cgf_perp_DETR__Detenir_estRapport::d_date'
                    },
                    {
                      name: 'Origine d\'acquisition',
                      type: 'select',
                      key: 'cgf_perp_DETR__Detenir_estRapport::d_acquisition',
                      options: [
                        'Achat',
                        'Donation',
                        'Succession',
                        'Achat comptant'
                      ]
                    },
                    {
                      name: 'Nature du bien',
                      type: 'select',
                      key: 'cgf_perp_detr_BI1__Bien::d_nature',
                      options: [
                        'Fond de commerce',
                        'Parts de société',
                        'Maison',
                        'Appartement',
                        'Studio',
                        'Local',
                        'Parking',
                        'Immeuble',
                        'Terrain',
                        'Livret',
                        'LDD',
                        'PERP',
                        'PER',
                        'PERCO',
                        'Retraite Madelin',
                        'Assurance vie',
                        'CSL',
                        'Cpte courant',
                        'Compte titre',
                        'Cpte courant asso.',
                        'Contrat de capi',
                        'CAT',
                      ]
                    },
                    {
                      name: 'Valeur (en €)',
                      type: 'text',
                      key: 'cgf_perp_detr_bi1_VLRP__Valeur_estPatrimoine::d_valeur'
                    },
                    {
                      name: 'Valeur d\'achat (en €)',
                      type: 'text',
                      key: 'cgf_perp_detr_bi1_VLRP__Valeur_estAchat::d_valeur'
                    },
                    {
                      name: 'Loyer mensuel perçu',
                      type: 'text',
                      key: 'cgf_perp_detr_bi1_VLRR__Valeur_estRevenu::d_valeur'
                    },
                    {
                      name: 'Observations',
                      type: 'text',
                      key: 'cgf_perp_detr_BI1__Bien::d_particularite'
                    }
                  ]
                }
              ]
            }, {
              label: 'Charges',
              fields: [
                {
                  key: 'cgf_perp_VLRC__Valeur_estCharge',
                  type: 'OneToManyFormInput',
                  headers: [
                    {
                      name: 'Objet',
                      type: 'text',
                      key: 'cgf_perp_VLRC__Valeur_estCharge::d_designation'
                    },
                    {
                      name: 'Montant annuel (en €)',
                      type: 'text',
                      key: 'cgf_perp_VLRC__Valeur_estCharge::d_valeur'
                    },
                    {
                      name: 'Date de début',
                      type: 'date',
                      key: 'cgf_perp_VLRC__Valeur_estCharge::d_debut'
                    },
                    {
                      name: 'Date de fin',
                      type: 'date',
                      key: 'cgf_perp_VLRC__Valeur_estCharge::d_fin'
                    },
                    {
                      name: 'Observations',
                      type: 'text',
                      key: 'cgf_perp_VLRC__Valeur_estCharge::d_particularite'
                    }
                  ]
                }
              ]
            }, {
              label: 'Impôts et taxes de l\'emprunteur principal',
              fields: [
                {
                  label: 'Impôts sur le revenu',
                  type: 'text',
                  key: 'cgf_perp_VLRC__Valeur_estImpotsRevenuN_1::d_valeur'
                },
                {
                  label: 'Revenu fiscal de référence N-1 (en €)',
                  type: 'text',
                  key: 'cgf_perp_VLRC__Valeur_estRevenuFiscalN_1::d_valeur'
                },
                {
                  label: 'Revenu fiscal de référence N-2 (en €)',
                  type: 'text',
                  key: 'cgf_perp_VLRC__Valeur_estRevenuFiscalN_2::d_valeur'
                },
                {
                  label: 'Taxe foncière N-1 (en €)',
                  type: 'text',
                  key: 'cgf_perp_VLRC__Valeur_estTaxeFonciereN_1::d_valeur'
                }
              ]
            }, {
              label: 'Impôts et taxes du co-emprunteur',
              fields: [
                {
                  label: 'Impôts sur le revenu',
                  type: 'text',
                  key: 'cgf_perp_liece_per_VLRC__Valeur_estImpotsRevenuN_1::d_valeur'
                },
                {
                  label: 'Revenu fiscal de référence N-1 (en €)',
                  type: 'text',
                  key: 'cgf_perp_liece_per_VLRC__Valeur_estRevenuFiscalN_1::d_valeur'
                },
                {
                  label: 'Revenu fiscal de référence N-2 (en €)',
                  type: 'text',
                  key: 'cgf_perp_liece_per_VLRC__Valeur_estRevenuFiscalN_2::d_valeur'
                },
                {
                  label: 'Taxe foncière N-1 (en €)',
                  type: 'text',
                  key: 'cgf_perp_liece_per_VLRC__Valeur_estTaxeFonciereN_1::d_valeur'
                }
              ]
            }
          ]
        },
        {
          number: 3,
          groups: [
            {
              label: 'Postes',
              fields: [
                {
                  key: 'cgf_perp_scr_prdp_PST__Poste',
                  type: 'OneToManyFormInput',
                  headers: [
                    {
                      name: 'Poste',
                      type: 'text',
                      key: 'cgf_perp_scr_prdp_PST__Poste::d_intitule'
                    },
                    {
                      name: 'Montant (en €)',
                      type: 'text',
                      key: 'cgf_perp_scr_prdp_pst_VLRC__Valeur_estCharge::d_valeur'
                    }
                  ]
                }, {
                  key: 'cgf_perp_scr_prdp_VLRA__Valeur_estApport::d_valeur',
                  label: 'Apport en liquidités :',
                  type: 'text'
                }, {
                  key: 'cgf_perp_scr_PRDP__Produit_estPret::d_duree',
                  label: 'Durée de prêt maximale souhaitée',
                  type: 'text'
                }, {
                  key: 'cgf_perp_scr_prdp_VLRM__Valeur_estMensualite::d_valeur',
                  label: 'Mensualité maximum souhaitée',
                  type: 'text'
                }, {
                  key: 'cgf_perp_scr_PRDP__Produit_estPret::d_particularite',
                  label: 'Remarques',
                  type: 'text'
                }
              ]
            }
          ]
        }
      ]
    }
  },
  methods: {
    changeFormTab (tabNumber, event) {
      if (this.validateForm()) {
        this.currentStep = tabNumber
      }
    },
    validateForm () {
      const currentTabInputs = document.querySelectorAll('input')
      if (this.currentStep === 1) {
        this.isPhoneNumberRequired()
      }
      let isValid = true
      for (const element of currentTabInputs) {
        element.classList.add('submitted')
        if (!element.reportValidity()) {
          isValid = false
        }
        if (element.type === 'checkbox' && this.model[element.id]) {
          this.model[element.id] = Number(element.checked)
        }
      }
      return isValid
    },
    async sendForm () {
      if (this.validateForm()) {
        for (const key in this.portalData) {
          if (this.portalData[key].length === 0) {
            delete this.portalData[key]
          }
        }
        this.changeDateFormat()
        this.$getFormToken().then(res => {
          const options = {
            apiUrl: this.$apiUrl,
            databaseName: this.$formDatabaseName,
            token: res
          }
          this.formDataApi = new DataApi(options)
          this.formDataApi.createRecord(
            'CGF_Cgform_demandePret', 
            this.model, 
            [{
              name: 'validerFormulaire',
              type: 'postrequest'
            }],
            this.portalData
          )
          .then((response) => {
            this.submitResponse = response
          })
          .catch((error) => {
            console.log(error)
            console.log(Object.getOwnPropertyNames(error))
            console.log(error.message)
            alert('Erreur : ' + error.data.messages[0].message)
          })
        })
      }
    },
    isPhoneNumberRequired () {
      if (this.model['cgf_perp_CTCF__Contact_estTelFixe::d_chaineDeCaracteres'] !== '' || this.model['cgf_perp_CTCP__Contact_estTelMobile::d_chaineDeCaracteres'] !== '') {
        document.getElementById('cgf_perp_CTCF__Contact_estTelFixe::d_chaineDeCaracteres').required = false
        document.getElementById('cgf_perp_CTCP__Contact_estTelMobile::d_chaineDeCaracteres').required = false
      } else {
        document.getElementById('cgf_perp_CTCF__Contact_estTelFixe::d_chaineDeCaracteres').required = true
        document.getElementById('cgf_perp_CTCP__Contact_estTelMobile::d_chaineDeCaracteres').required = true
      }
    },
    handleInput ($event) {
      console.log($event)
    },
    changeDateFormat () {
      const regex = /\d{4}-\d{2}-\d{2}/
      for (const key in this.model) {
        if (regex.test(this.model[key])) {
          this.model[key] = moment(this.model[key], 'YYYY-MM-DD').format('MM/DD/YYYY')
        }
      }
      for (const portalKey in this.portalData) {
        for (const index in this.portalData[portalKey]) {
          for (const key in this.portalData[portalKey][index]) {
            if (regex.test(this.portalData[portalKey][index][key])) {
              this.portalData[portalKey][index][key] = moment(this.portalData[portalKey][index][key], 'YYYY-MM-DD').format('MM/DD/YYYY')
            }
          }
        }
      }
    }
  },
  computed: {
    isCouple: function (val) {
      if (this.model['cgf_PERP__Personne_estPrincipal::d_situationFamiliale'] === 'Marié' || 
          this.model['cgf_PERP__Personne_estPrincipal::d_situationFamiliale'] === 'PACS') 
      {
        return 1
      }
      return 0
    },
    getCoemprunteurName () {
      if (!this.portalData) {
        return []
      }
      let coemprunteurName = []
      for (let coemprunteur of this.portalData['cgf_perp_liece_PER__Personne']) {
        coemprunteurName.push(coemprunteur['cgf_perp_liece_PER__Personne::d_nom'] + ' ' + coemprunteur['cgf_perp_liece_PER__Personne::d_prenom'])
      }
      console.log(coemprunteurName)
      return coemprunteurName
    }
  }
}
