//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Vue from 'vue'
import DataApi from 'fm-data-api'
import { SidebarPlugin } from 'bootstrap-vue'

Vue.use(SidebarPlugin)
const moment = require('moment')
require('moment/locale/fr')

Vue.use(require('vue-moment'), {
  moment
})

export default {
  name: 'Compte',
  data () {
    return {
      user: {},
      produits: {},
      notifications: {}
    }
  },
  methods: {
    async loadUser () {
      const options = {
        apiUrl: this.$apiUrl,
        databaseName: this.$databaseName,
        token: await this.$getMainToken()
      }
      const dataApi = new DataApi(options)
      const scripts = [{
        name: 'chercheCompte',
        type: 'prerequest'
      }]
      dataApi.getRecords('W_Utilisateur', '', '', '', [], scripts)
        .then((response) => {
          this.user = Vue.removeLayout(response[0].fieldData)
          const dataProduits = {}
          let i = 0
          Object.values(response[0].portalData.acc_uti_sou_PRD__Produit).forEach((produit) => {
            dataProduits[i] = Vue.removeLayout(produit)
            i++
          })
          this.produits = dataProduits
          const dataNotifs = {}
          i = 0
          Object.values(response[0].portalData.acc_uti_prd_NOT__Notification).forEach((notif) => {
            dataNotifs[i] = Vue.removeLayout(notif)
            i++
          })
          this.notifications = dataNotifs
        })
    }
  },
  created () {
    if (Vue.prototype.$isLogged === false) {
      this.$router.push({ name: 'Connexion' })
    } else {
      this.loadUser()
    }
  }
}
