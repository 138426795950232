//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DataApi from '../../dependencies/index'
import { getFocus } from '../../mixins/getFocus'


export default {
  name: 'ImmoContact',
  mixins: [getFocus],
  props: {
    siteCode: String
  },
  components: {
    AutoCompleteGouvApi: () => import('../../components/AutoCompleteGouvApi')
  },
  data() {
    return {
      fiche: {
        nom: '',
        prenom: '',
        email: '',
        telephone: '',
        adresse: '',
        codePostal: '',
        ville: '',
        pays: '',
        questions: '',
        rgpd: '',
        type: 'contact',
        destinataire: this.siteCode
      },
      formSent: false,
      focus: {}
    }
  },
  methods: {
    async sendData() {
      const demandeToSend = this.fiche
      demandeToSend.rgpd = + demandeToSend.rgpd
      const options = {
        apiUrl: this.$apiUrl,
        databaseName: this.$databaseName,
        token: await this.$getMainToken()
      }
      this.dataApi = new DataApi(options)
      this.dataApi.createRecord(
        'W_DemandeContact',
        demandeToSend,
        [{
          name: 'validerFormulaire',
          type: 'postrequest'
        }]
      )
        .then((response) => {
          console.log(response)
          this.formSent = true
        })
    },
    changeGPSData(data) {
      if (data.commune) {
        this.fiche.ville = data.commune.value
      }
      if (data.pays) {
        this.fiche.pays = data.pays.value
      }
    }
  },
  async beforeMount() {
    await this.getFocus(this.siteCode, 'Contact')
      .then(res => {
        this.focus = res
      })
  }
}
