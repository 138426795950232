//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Vue from 'vue'
import DataApi from '../../dependencies/index'
import { FormGroupPlugin, FormPlugin, FormInputPlugin, FormTextareaPlugin, FormCheckboxPlugin, InputGroupPlugin, ButtonPlugin } from 'bootstrap-vue'
import { getFocus } from '../../mixins/getFocus'

Vue.use(FormGroupPlugin)
Vue.use(FormPlugin)
Vue.use(FormInputPlugin)
Vue.use(FormTextareaPlugin)
Vue.use(FormCheckboxPlugin)
Vue.use(InputGroupPlugin)
Vue.use(ButtonPlugin)

export default {
  name: 'Contact',
  mixins: [getFocus],
  components: {
    ColorPointsHeader: () => import('../../components/ColorPointsHeader'),
    AutoCompleteGouvApi: () => import('../../components/AutoCompleteGouvApi')
  },
  data () {
    return {
      pinkHeaderMessage: {
        titre: '',
        sousTitre: ''
      },
      fiche: {
        nom: '',
        prenom: '',
        email: '',
        telephone: '',
        adresse: '',
        codePostal: '',
        ville: '',
        pays: '',
        questions: '',
        rgpd: '',
        type: 'contact',
        destinataire: 'ti'
      },
      error: {
        nom: null,
        prenom: null,
        questions: null,
        rgpd: null,
        email: null
      },
      formSent: false,

      /* message d'erreur affiché pour un champs requis vide */
      errorMessage: 'Ce champs est requis',
      focus: {}
    }
  },
  methods: {
    checkErrors () {
      this.error.nom = (!!this.fiche.nom)
      this.error.prenom = (!!this.fiche.prenom)
      this.error.questions = (!!this.fiche.questions)
      this.error.email = (!!this.fiche.email)
      this.error.rgpd = (!!this.fiche.rgpd && this.fiche.rgpd !== '0')
      let stop = false
      Object.values(this.error).forEach((value) => {
        if (value === false) {
          stop = true
        }
      })
      if (stop) {
        setTimeout(() => {
          Object.keys(this.error).forEach((key) => {
            this.error[key] = null
          })
        }, 3000)
      } else {
        this.addDemande()
      }
    },
    async addDemande () {
      const demandeToSend = this.fiche
      const options = {
        apiUrl: this.$apiUrl,
        databaseName: this.$databaseName,
        token: await this.$getMainToken()
      }
      this.dataApi = new DataApi(options)
      this.dataApi.createRecord(
        'W_DemandeContact', 
        demandeToSend,
        [{
          name: 'validerFormulaire',
          type: 'postrequest'
        }]
      )
      .then((response) => {
        console.log(response)
        this.formSent = true
      })
    },
    changeGPSData (data) {
      if (data.cp !== '' && data.cp !== undefined) {
        this.fiche.adresse = data.adresse
      }
      if (data.cp !== '' && data.cp !== undefined) {
        this.fiche.codePostal = data.cp
      }
      if (data.commune !== '' && data.commune !== undefined) {
        this.fiche.ville = data.commune
      }
      this.fiche.pays = data.pays
    }
  },
  async beforeMount () {
    await this.getFocus('Tolus Immo', 'Contact')
      .then(res => {
        this.focus = res
          this.pinkHeaderMessage.titre = this.focus.cartouche ? this.focus.cartouche.titre : ''
          this.pinkHeaderMessage.sousTitre = this.focus.cartouche ? this.focus.cartouche.texte : ''
      })
  }
}
