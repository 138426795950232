
// This exports the plugin object.
export default {
  // The install method will be called with the Vue constructor as
  // the first argument, along with possible options
  install (Vue, options) {
    Vue.alertMailParam = function (mail, request = false) {
      const length = Math.floor((Math.random() * 5) + 3)
      const arobase = makeid(length)
      let requ = (request !== undefined && request !== null && request.constructor === Array) ? JSON.stringify(request) : request
      let param = mail.replace('@', arobase) + '|' + arobase
      if (request !== false) {
        requ = requ.substring(2)
        requ = requ.substring(0, requ.length - 2)
        let find = '"fields":'
        let regexp = new RegExp(find, 'g')
        requ = requ.replace(regexp, '')
        find = '"fieldname":'
        regexp = new RegExp(find, 'g')
        requ = requ.replace(regexp, '')
        find = ',"fieldvalue"'
        regexp = new RegExp(find, 'g')
        requ = requ.replace(regexp, '')
        find = '"'
        regexp = new RegExp(find, 'g')
        requ = requ.replace(regexp, '\\"')
        param += '|' + requ
      }
      return param
    }
    function makeid (length) {
      let result = ''
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      const charactersLength = characters.length
      for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength))
      }
      return result
    }
  }
}
