//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DataApi from 'fm-data-api'
import { getFocus } from '../../mixins/getFocus'

export default {
  name: 'Agence',
  mixins: [getFocus],
  components: {
    ColorPointsHeader: () => import('../../components/ColorPointsHeader')
  },
  data () {
    return {
      pinkHeaderMessage: {
        titre: '',
        sousTitre: ''
      },
      collaborateurs: {},
      focus: {}
    }
  },
  methods: {
    async loadCollaborateurs () {
      const options = {
        apiUrl: this.$apiUrl,
        databaseName: this.$databaseName,
        token: await this.$getMainToken()
      }
      this.dataApi = new DataApi(options)
      this.dataApi.findRecords('W_Collaborateur', { 'col_SOC__Societe::d_nomSociete': 'Tolus Immo' }, '', '', '')
        .then((response) => {
          this.insertCollaborateurs(response)
        })
    },
    insertCollaborateurs (collaborateursList) {
      if (collaborateursList.length > 0) {
        const collaborateursToInsert = {}
        collaborateursList.forEach(function (value, index) {
          const dataToInsert = value.fieldData
          dataToInsert.recordId = value.recordId
          collaborateursToInsert[dataToInsert.ordre] = dataToInsert
        })
        this.collaborateurs = collaborateursToInsert
      } else {
        this.collaborateurs = {}
      }
    }
  },
  created () {
    this.loadCollaborateurs()
  },
  async beforeMount () {
    await this.getFocus('Tolus Immo', 'Agence')
      .then(res => {
        this.focus = res
          this.pinkHeaderMessage.titre = this.focus.cartouche ? this.focus.cartouche.titre : ''
          this.pinkHeaderMessage.sousTitre = this.focus.cartouche ? this.focus.cartouche.texte : ''
      })
  }
}
