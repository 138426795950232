import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home'
import Focus from '../views/Focus'
import TolusImmo from '../views/TI/TolusImmo'
import CGCourtage from '../views/CGC/CGCourtage'
// Compte
import Compte from '../views/Compte/Compte.vue'
import Connexion from '../views/Compte/Connexion.vue'
import TableauDeBord from '../views/Compte/TableauDeBord.vue'
// TI
import Achat from '../views/TI/Achat'
import Bien from '../views/TI/Bien'
import Vente from '../views/TI/Vente'
import Investir from '../views/TI/Investir'
import InfosTolus from '../views/TI/Infos'
import ContactTI from '../views/TI/Contact'
import Agence from '../views/TI/Agence'
// CGC
import Prevoyance from '../views/CGC/Prevoyance'
import InfosCGC from '../views/CGC/Infos'
import Equipe from '../views/CGC/Equipe'
import ContactCGC from '../views/CGC/Contact'
import IngenierieConseiPatrimoniaux from '../views/CGC/IngenierieConseilPatrimoniaux'
import EpargneRetraite from '../views/CGC/EpargneRetraite'
import AssurancePrevoyance from '../views/CGC/AssurancePrevoyance'
import FinancementImmobilierProfessionnels from '../views/CGC/FinancementImmobilierProfessionnels'
// Immobilier
import Immobilier from '../views/Immobilier/Immobilier'
import ImmoHome from '../views/Immobilier/Home'
import AdList from '../views/Immobilier/AdList'
import AdDetails from '../views/Immobilier/AdDetails'
import Sell from '../views/Immobilier/Sell'
import ImmoAgence from '../views/Components/Agence'
import ImmoContact from '../views/Components/Contact'
import ImmoMentionsLegales from '../views/Components/MentionsLegales'
import BaremeHonoraire from '../views/Immobilier/BaremeHonoraire'
// Courtage
import Courtage from '../views/Courtage/Courtage'
import CourtageHome from '../views/Courtage/Home'
import CourtageAgence from '../views/Components/Agence'
import CourtageContact from '../views/Components/Contact'
import Financement from '../views/Courtage/Financement'
import Assurance from '../views/Courtage/Assurance'
import DemandePretForm from '../views/Courtage/DemandePretForm'
import MentionsLegales from '../views/Components/MentionsLegales'
// Patrimoine
import Patrimoine from '../views/Patrimoine/Patrimoine'
import PatrimoineHome from '../views/Patrimoine/Home'
import PatrimoineAgence from '../views/Components/Agence'
import PatrimoineContact from '../views/Components/Contact'
import Ingenierie from '../views/Patrimoine/Ingenierie'
import Epargne from '../views/Patrimoine/Epargne'
import EtudePatrimonialeForm from '../views/Patrimoine/EtudePatrimonialeForm'
import RecrutementForm from '../views/Components/RecrutementForm'
import GPMentionsLegales from '../views/Components/MentionsLegales'

Vue.use(VueRouter)

const Bareme = () => import('../views/TI/async/Bareme.vue')

const Mentions = () => import('../views/TI/async/Mentions.vue')

const Nous = () => import('../views/TI/async/Nous.vue')

const AssuranceBien = () => import('../views/CGC/async/AssuranceBien.vue')

const AssuranceCredit = () => import('../views/CGC/async/AssuranceCredit.vue')

const AssuranceRevenus = () => import('../views/CGC/async/AssuranceRevenus.vue')

const AutoMoto = () => import('../views/CGC/async/AutoMoto.vue')

const FraisMedicaux = () => import('../views/CGC/async/FraisMedicaux.vue')

const MentionsCGC = () => import('../views/CGC/async/Mentions.vue')

const NousCGC = () => import('../views/CGC/async/Nous.vue')

const FinancementImmobilier = () => import('../views/CGC/async/FinancementImmobilier.vue')

const RachatCredits = () => import('../views/CGC/async/RachatCredits.vue')

const RenegociationPret = () => import('../views/CGC/async/RenegociationPret.vue')

const PretsProfessionnels = () => import('../views/CGC/async/PretsProfessionnels.vue')

const PretsHypotecaires = () => import('../views/CGC/async/PretsHypotecaires.vue')

const Remere = () => import('../views/CGC/async/Remere.vue')

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: '/Immobilier/Accueil'
  },
  // Compte
  {
    path: '/compte/',
    name: 'Compte',
    component: Compte,
    children: [
      {
        path: 'connexion.html',
        name: 'Connexion',
        component: Connexion
      },
      {
        path: 'tableauDeBord.html',
        name: 'TableauDeBord',
        component: TableauDeBord,
        props: true
      }
    ]
  },
  // TI
  {
    path: '/TI/',
    name: 'tolusImmo',
    component: TolusImmo,
    children: [
      {
        path: 'achat/:typeSearched?.html',
        name: 'Achat',
        component: Achat,
        props: true
      },
      {
        path: '/focus/:focusRecordId.html',
        name: 'Tolus Immo_Focus',
        component: Focus,
        props: true
      },
      {
        path: 'achat/:typeSearched/:bienRecordId.html',
        name: 'Bien',
        component: Bien,
        props: true
      },
      {
        path: 'vente.html',
        name: 'Vente',
        component: Vente
      },
      {
        path: 'investir.html',
        name: 'Investir',
        component: Investir
      },
      {
        path: 'contact.html',
        name: 'ContactTI',
        component: ContactTI
      },
      {
        path: 'infos.html',
        name: 'InfosTolus',
        component: InfosTolus
      },
      {
        path: 'agence.html',
        name: 'Agence',
        component: Agence
      },
      {
        path: 'bareme.html',
        name: 'Bareme',
        component: Bareme
      },
      {
        path: 'mentions-legales.html',
        name: 'Mentions',
        component: Mentions
      },
      {
        path: 'nous.html',
        name: 'NousCGC',
        component: NousCGC
      }
    ]
  },
  // CGC
  {
    path: '/CGC/',
    name: 'CGCourtage',
    component: CGCourtage,
    children: [
      {
        path: '/focus/:focusRecordId.html',
        name: 'CG Courtage_Focus',
        component: Focus,
        props: true
      },
      {
        path: 'prevoyance.html',
        name: 'Prevoyance',
        component: Prevoyance
      },
      {
        path: 'infos.html',
        name: 'InfosCGC',
        component: InfosCGC
      },
      {
        path: 'equipe.html',
        name: 'Equipe',
        component: Equipe
      },
      {
        path: 'contact.html',
        name: 'ContactCGC',
        component: ContactCGC
      },
      {
        path: 'assuranceBien.html',
        name: 'AssuranceBien',
        component: AssuranceBien
      },
      {
        path: 'assuranceCredit.html',
        name: 'AssuranceCredit',
        component: AssuranceCredit
      },
      {
        path: 'assuranceRevenus.html',
        name: 'AssuranceRevenus',
        component: AssuranceRevenus
      },
      {
        path: 'autoMoto.html',
        name: 'AutoMoto',
        component: AutoMoto
      },
      {
        path: 'fraisMedicaux.html',
        name: 'FraisMedicaux',
        component: FraisMedicaux
      },
      {
        path: 'mentions-legales.html',
        name: 'MentionsCGC',
        component: MentionsCGC
      },
      {
        path: 'nous.html',
        name: 'Nous',
        component: Nous
      },
      {
        path: 'financementImmobilier.html',
        name: 'FinancementImmobilier',
        component: FinancementImmobilier
      },
      {
        path: 'rachatCredits.html',
        name: 'RachatCredits',
        component: RachatCredits
      },
      {
        path: 'renegociationPret.html',
        name: 'RenegociationPret',
        component: RenegociationPret
      },
      {
        path: 'pretsProfessionnels.html',
        name: 'PretsProfessionnels',
        component: PretsProfessionnels
      },
      {
        path: 'pretsHypotecaires.html',
        name: 'PretsHypotecaires',
        component: PretsHypotecaires
      },
      {
        path: 'remere.html',
        name: 'Remere',
        component: Remere
      },
      {
        path: 'ingenierieConseilPatrimoniaux.html',
        name: 'IngenierieConseilPatrimoniaux',
        component: IngenierieConseiPatrimoniaux
      },
      {
        path: 'epargneRetraite.html',
        name: 'EpargneRetraite',
        component: EpargneRetraite
      },
      {
        path: 'assurancePrevoyance.html',
        name: 'AssurancePrevoyance',
        component: AssurancePrevoyance
      },
      {
        path: 'financementImmobilierProfessionnels.html',
        name: 'FinancementImmobilierProfessionnels',
        component: FinancementImmobilierProfessionnels
      }
    ]
  },
  // Immobilier
  {
    name: 'Immobilier',
    path: '/Immobilier/',
    component: Immobilier,
    children: [
      {
        name: 'ImmoHome',
        path: 'Accueil',
        component: ImmoHome
      },
      {
        name: 'AdList',
        path: 'Resultats',
        component: AdList
      },
      {
        name: 'AdDetails',
        path: 'Resultats/:recordId',
        component: AdDetails,
        props: true
      },
      {
        name: 'Sell',
        path: 'Vendre',
        component: Sell
      },
      {
        name: 'ImmoAgence',
        path: 'Agence',
        component: ImmoAgence,
        props: { siteCode: 'ti' }
      },
      {
        name: 'ImmoContact',
        path: 'Contact',
        component: ImmoContact,
        props: { siteCode: 'ti' }
      },
      {
        name: 'TIRecrutementForm',
        path: 'Recrutement',
        component: RecrutementForm,
        props: { siteCode: 'ti' }
      },
      {
        name: 'ImmoMentionsLegales',
        path: 'MentionsLegales',
        component: ImmoMentionsLegales,
        props: { siteCode: 'ti' }
      },
      {
        name: 'BaremeHonoraire',
        path: 'BaremeHonoraire',
        component: BaremeHonoraire
      }
    ]
  },{
    name: 'Courtage',
    path: '/Courtage/',
    component: Courtage,
    children: [
      {
        name: 'CourtageHome',
        path: 'Accueil',
        component: CourtageHome
      },
      {
        name: 'Financement',
        path: 'Financement',
        component: Financement
      },
      {
        name: 'Assurance',
        path: 'Assurance',
        component: Assurance
      },
      {
        name: 'CourtageAgence',
        path: 'Agence',
        component: CourtageAgence,
        props: { siteCode: 'cg' }
      },
      {
        name: 'CourtageContact',
        path: 'Contact',
        component: CourtageContact,
        props: { siteCode: 'cg' }
      },
      {
        name: 'CGRecrutementForm',
        path: 'Recrutement',
        component: RecrutementForm,
        props: { siteCode: 'cg' }
      },
      {
        name: 'DemandePretForm',
        path: 'Situation',
        component: DemandePretForm
      },
      {
        name: 'MentionsLegales',
        path: 'MentionsLegales',
        component: MentionsLegales,
        props: { siteCode: 'cg' }
      }
    ]
  },{
    name: 'Patrimoine',
    path: '/Patrimoine/',
    component: Patrimoine,
    children: [
      {
        name: 'PatrimoineHome',
        path: 'Accueil',
        component: PatrimoineHome
      },
      {
        name: 'Ingenierie',
        path: 'Ingenierie',
        component: Ingenierie
      },
      {
        name: 'Epargne',
        path: 'Epargne',
        component: Epargne
      },
      {
        name: 'PatrimoineAgence',
        path: 'Agence',
        component: PatrimoineAgence,
        props: { siteCode: 'gp' }
      },
      {
        name: 'PatrimoineContact',
        path: 'Contact',
        component: PatrimoineContact,
        props: { siteCode: 'gp' }
      },
      {
        name: 'EtudePatrimonialeForm',
        path: 'Situation',
        component: EtudePatrimonialeForm
      },
      {
        name: 'GPRecrutementForm',
        path: 'Recrutement',
        component: RecrutementForm,
        props: { siteCode: 'gp' }
      },
      {
        name: 'GPMentionsLegales',
        path: 'MentionsLegales',
        component: GPMentionsLegales,
        props: { siteCode: 'gp' }
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    window.scrollTo(0, 0)
  }
})

export default router
