// removeFMLayout.js

// This exports the plugin object.
export default {
  // The install method will be called with the Vue constructor as
  // the first argument, along with possible options
  install (Vue, options) {
    Vue.removeLayout = function (dataToTransform) {
      const dataToReturn = {}
      const regex = new RegExp('::')
      Object.entries(dataToTransform).forEach(([key, value]) => {
        if (regex.test(key)) {
          key = key.split('::')[1]
        }
        dataToReturn[key] = value
      })
      return dataToReturn
    }
  }
}
