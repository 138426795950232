//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getFocus } from '../../mixins/getFocus'
import SiteLink from '../Components/SiteLink.vue'

export default {
  name: 'Ingenierie',
  mixins: [getFocus],
  components: {
    SiteLink
  },
  data() {
    return {
      focus: {}
    }
  },
  async beforeMount() {
    this.getFocus('gp', 'Ingénierie & conseils patrimoniaux')
    .then(res => {
        this.focus = res
    })
  }
}
