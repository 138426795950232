//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getFocus } from '../../mixins/getFocus'

export default {
  name: 'MentionsLegales',
  mixins: [getFocus],
  props: {
    siteCode: String
  },
  data() {
    return {
      focus: {},
    }
  },
  async beforeMount() {
    await this.getFocus(this.siteCode, 'Mentions Légales')
      .then(res => {
        this.focus = res
      })
  }
}
