//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Vue from 'vue'
import { FormPlugin, FormInputPlugin, InputGroupPlugin, ButtonPlugin, PaginationPlugin } from 'bootstrap-vue'
import DataApi from 'fm-data-api'
import { getFocus } from '../../mixins/getFocus'

Vue.use(FormPlugin)
Vue.use(FormInputPlugin)
Vue.use(InputGroupPlugin)
Vue.use(ButtonPlugin)
Vue.use(PaginationPlugin)

export default {
  name: 'Achat',
  mixins: [getFocus],
  components: {
    ColorPointsHeader: () => import('../../components/ColorPointsHeader'),
    MultipleAutoComplete: () => import('../../components/MultipleAutoComplete'),
    BienPreview: () => import('../../components/BienPreview.vue'),
    BienAlertEmail: () => import('../../components/BienAlertEmail.vue')
  },
  data () {
    return {
      showAlertEmail: false,
      display: false,
      biens: {},
      biensEmpty: false,
      dataApi: null,
      focus: {},
      // variables actuelles de la barre de recherche
      bien: {
        type: null,
        nbrPieces: null,
        surface: null,
        coordinates: null,
        rayon: '20',
        budget: null
      },
      pinkHeaderMessage: {
        titre: '',
        sousTitre: ''
      },
      // dernières variables de la barre de recherche pour comparaison
      oldBien: {
        type: null,
        nbrPieces: null,
        surface: null,
        coordinates: null,
        rayon: null,
        budget: null
      },
      typeBienOptions: [
        { value: 'Maison / Villa', label: 'Maison / Villa' },
        { value: 'Appartement', label: 'Appartement' },
        { value: 'Terrain', label: 'Terrain' },
        { value: 'Commerce', label: 'Commerce' },
        { value: 'Bureau / Local commercial', label: 'Bureau / Local commercial' },
        { value: 'Parking', label: 'Parking' },
        { value: 'Immeuble', label: 'Immeuble' },
        { value: 'Hangar', label: 'Hangar' },
        { value: 'Hôtel particulier', label: 'Hôtel particulier' },
        { value: 'Droit au bail', label: 'Droit au bail' },
        { value: 'Divers', label: 'Divers' }
      ],
      nbrPiecesBiensOptions: [
        { value: '1', label: '1' },
        { value: '2', label: '2' },
        { value: '3', label: '3' },
        { value: '4', label: '4' },
        { value: '5', label: '5' },
        { value: '6', label: '6' },
        { value: '7', label: '7' },
        { value: '8', label: '8' }
      ],
      rayonBiensOptions: [
        { value: '5', label: '5' },
        { value: '10', label: '10' },
        { value: '20', label: '20' },
        { value: '50', label: '50' }
      ],
      surfaceMarks: {
        0: {
          style: {
            color: '#49507B'
          },
          label: this.$createElement('small', '0m²')
        },
        800: {
          style: {
            color: '#49507B'
          },
          label: this.$createElement('small', '800m²')
        }
      },
      budgetMarks: {
        0: {
          style: {
            color: '#49507B'
          },
          label: this.$createElement('small', '0 €')
        },
        1500000: {
          style: {
            color: '#49507B'
          },
          label: this.$createElement('small', '1 500 000 €')
        }
      },
      // variables des critères de recherche (configiration initiale)
      criteres: [
        {
          label: 'Date',
          value: 'd_dateAnnonce'
        },
        {
          label: 'Prix',
          value: 'd_prix'
        },
        {
          label: 'Surface',
          value: 'd_surface'
        },
        {
          label: 'Nbre de pièces',
          value: 'd_nbrePieces'
        },
        {
          label: 'Commune',
          value: 'd_commune'
        }
      ],
      critereTri1: 'd_dateAnnonce',
      critereTri2: 'd_prix',
      // Éléments configurant la pagination
      currentPage: 1,
      nbreBiens: 0,
      biensPerPage: 9
    }
  },
  props: {
    typeSearched: String,
    default: null
  },
  computed: {
    offset: function () {
      const offset = ((this.currentPage - 1) * this.biensPerPage).toString()
      return offset
    }
  },
  watch: {
    currentPage: function (val, oldVal) {
      this.obtenirBiens()
    }
  },
  methods: {
    async obtenirBiens () {
      const sort = []
      if (this.critereTri1 !== '' && this.critereTri1 !== null) {
        sort.push(
          {
            fieldName: this.critereTri1,
            sortOrder: 'descend'
          }
        )
      }

      if (this.critereTri2 !== '' && this.critereTri2 !== null) {
        sort.push(
          {
            fieldName: this.critereTri2,
            sortOrder: 'descend'
          }
        )
      }
      let sortToSend = JSON.stringify(sort)
      sortToSend = JSON.parse(sortToSend)
      const arrayFields = []
      let typeSearched = ''
      if (this.bien.type.length <= 1) {
        typeSearched = (this.bien.type.length === 0) ? 'bien' : this.bien.type[0]
      } else {
        typeSearched = 'bien'
      }
      if (window.location.href.split('/TI')[1] !== `/achat/${this.$options.filters.camelCase(typeSearched)}.html`) {
        this.$router.replace(`${this.$options.filters.camelCase(typeSearched)}.html`)
      }
      let query = []
      if (this.bien.type.length > 0) {
        this.bien.type.forEach(v => {
          const type = v
          if (this.bien.nbrPieces.length > 0) {
            this.bien.nbrPieces.forEach(val => {
              const arrayFields = []
              arrayFields.push({ fieldname: 'd_type', fieldvalue: `==${type}` })
              arrayFields.push({ fieldname: 'd_nbrPieces', fieldvalue: val })
              if (this.bien.surface[0].toString() !== Object.entries(this.surfaceMarks)[0][0] || this.bien.surface[1].toString() !== Object.entries(this.surfaceMarks)[1][0]) {
                arrayFields.push({ fieldname: 'd_surface', fieldvalue: `${this.bien.surface[0]}...${this.bien.surface[1]}` })
              }
              if (this.bien.budget[0].toString() !== Object.entries(this.budgetMarks)[0][0] || this.bien.budget[1].toString() !== Object.entries(this.budgetMarks)[1][0]) {
                arrayFields.push({ fieldname: 'd_prix', fieldvalue: `${this.bien.budget[0]}...${this.bien.budget[1]}` })
              }
              if (arrayFields.length > 0) {
                query.push({ fields: arrayFields })
              }
            }, this)
          } else {
            const arrayFields = []
            arrayFields.push({ fieldname: 'd_type', fieldvalue: `==${type}` })
            if (this.bien.surface[0].toString() !== Object.entries(this.surfaceMarks)[0][0] || this.bien.surface[1].toString() !== Object.entries(this.surfaceMarks)[1][0]) {
              arrayFields.push({ fieldname: 'd_surface', fieldvalue: `${this.bien.surface[0]}...${this.bien.surface[1]}` })
            }
            if (this.bien.budget[0].toString() !== Object.entries(this.budgetMarks)[0][0] || this.bien.budget[1].toString() !== Object.entries(this.budgetMarks)[1][0]) {
              arrayFields.push({ fieldname: 'd_prix', fieldvalue: `${this.bien.budget[0]}...${this.bien.budget[1]}` })
            }
            if (arrayFields.length > 0) {
              query.push({ fields: arrayFields })
            }
          }
        }, this)
      } else {
        if (this.bien.nbrPieces.length > 0) {
          this.bien.nbrPieces.forEach(val => {
            const arrayFields = []
            arrayFields.push({ fieldname: 'd_nbrPieces', fieldvalue: val })
            if (this.bien.surface[0].toString() !== Object.entries(this.surfaceMarks)[0][0] || this.bien.surface[1].toString() !== Object.entries(this.surfaceMarks)[1][0]) {
              arrayFields.push({ fieldname: 'd_surface', fieldvalue: `${this.bien.surface[0]}...${this.bien.surface[1]}` })
            }
            if (this.bien.budget[0].toString() !== Object.entries(this.budgetMarks)[0][0] || this.bien.budget[1].toString() !== Object.entries(this.budgetMarks)[1][0]) {
              arrayFields.push({ fieldname: 'd_prix', fieldvalue: `${this.bien.budget[0]}...${this.bien.budget[1]}` })
            }
            if (arrayFields.length > 0) {
              query.push({ fields: arrayFields })
            }
          }, this)
        } else {
          if (this.bien.surface[0].toString() !== Object.entries(this.surfaceMarks)[0][0] || this.bien.surface[1].toString() !== Object.entries(this.surfaceMarks)[1][0]) {
            arrayFields.push({ fieldname: 'd_surface', fieldvalue: `${this.bien.surface[0]}...${this.bien.surface[1]}` })
          }
          if (this.bien.budget[0].toString() !== Object.entries(this.budgetMarks)[0][0] || this.bien.budget[1].toString() !== Object.entries(this.budgetMarks)[1][0]) {
            arrayFields.push({ fieldname: 'd_prix', fieldvalue: `${this.bien.budget[0]}...${this.bien.budget[1]}` })
          }
          if (arrayFields.length > 0) {
            query.push({ fields: arrayFields })
          }
        }
      }

      if (this.bien.coordinates !== null && this.bien.coordinates) {
        let coordinatesArray = this.bien.coordinates.split('@')
        await this.dataApi.setGlobalFields('', {
          "ANN__Annonce::g_latLieu": coordinatesArray[0],
          "ANN__Annonce::g_lonLieu": coordinatesArray[1],
          "ANN__Annonce::g_rayonLieu": this.bien.rayon
        })
        if (query.length) {
          query[0].fields.push({
            fieldname: 'd_dansLaZoneDeRecherche',
            fieldvalue: 1
          })
        } else {
          query.push({ 
            fields: [{
              fieldname: 'd_dansLaZoneDeRecherche',
              fieldvalue: 1
            }] 
          })
        }
      }
      
      // instancie un getRecords si aucun paramètre de requête, un findRecords sinon
      if (query.length > 0) {
        query = JSON.stringify(query)
        query = JSON.parse(query)
        this.dataApi.findRecords('W_Annonce', query, sortToSend, this.offset, this.biensPerPage.toString(), [], [], true)
          .then((response) => {
            this.nbreBiens = (response.dataInfo === undefined) ? 0 : response.dataInfo.foundCount
            response.data = Vue.removeLayout(response.data)
            this.insertBiens(response.data)
          })
      } else {
        this.dataApi.getRecords('W_Annonce', sortToSend, this.offset, this.biensPerPage.toString(), [], [], true)
          .then((response) => {
            this.nbreBiens = (response.dataInfo === undefined) ? 0 : response.dataInfo.foundCount
            response.data = Vue.removeLayout(response.data)
            this.insertBiens(response.data)
          })
      }
    },
    insertBiens (biensL) {
      const biensList = (biensL === undefined) ? [] : biensL

      if (Object.values(biensList).length > 0) {
        this.biensEmpty = false
        const biensToInsert = {}
        Object.entries(biensList).forEach(function ([index, value]) {
          const dataToInsert = value.fieldData
          dataToInsert.recordId = value.recordId
          biensToInsert[index] = dataToInsert
        })
        this.biens = biensToInsert
      } else {
        this.biensEmpty = true
        this.biens = {}
      }
    },
    getbienLink (type, recordId) {
      const camelCasedType = this.$options.filters.camelCase(type)
      return `TI/achat/${camelCasedType}/${recordId}.html`
    },
    changeGPSData (coordinates, cityNames) {
      this.bien.coordinates = coordinates
      this.bien.cityNames = cityNames
    },
    async loadBiens () {
      const options = {
        apiUrl: this.$apiUrl,
        databaseName: this.$databaseName,
        token: await this.$getMainToken()
      }
      this.dataApi = new DataApi(options)
      const sortToSend = [
        {
          fieldName: this.critereTri1,
          sortOrder: 'descend'
        },
        {
          fieldName: this.critereTri2,
          sortOrder: 'descend'
        }
      ]
      const type = this.bien.type
      if (type.length === 0) {
        this.dataApi.getRecords('W_Annonce', sortToSend, 0, this.biensPerPage.toString(), [], [], true)
          .then((response) => {
            this.nbreBiens = (response.dataInfo === undefined) ? 0 : response.dataInfo.foundCount
            this.insertBiens(response.data)
            this.display = true
          })
      } else {
        const query = [
          {
            fields: [
              { fieldname: 'd_type', fieldvalue: this.bien.type[0] }
            ]
          }
        ]
        this.dataApi.findRecords('W_Annonce', query, sortToSend, 0, this.biensPerPage.toString(), [], [], true)
          .then((response) => {
            this.nbreBiens = (response.dataInfo === undefined) ? 0 : response.dataInfo.foundCount
            response.data = Vue.removeLayout(response.data)
            this.insertBiens(response.data)
            this.display = true
          })
      }
    },
    formatTooltipSurface (value) {
      return value + 'm'
    },
    formatTooltipBudget (value) {
      value = parseInt(value)
      if (value < 999) {
        return value + ' €'
      }
      return this.$options.filters.formatMoney(value) + ' €'
    },
    classStatus (value) {
      // "En vente" + "Sous offre" + "Sous compromis" + "Vendu"
      if (value !== '' && value !== undefined) {
        return this.$options.filters.camelCase(value)
      }
      return ''
    }
  },
  async beforeMount () {
    switch (this.typeSearched) {
      case 'bien':
        this.bien.type = []
        break
      case 'maisonVilla':
        this.bien.type = ['Maison / Villa']
        break
      case 'droitAuBail':
        this.bien.type = ['Droit au bail']
        break
      case 'bureauLocalCommercial':
        this.bien.type = ['Bureau / Local commercial']
        break
      case 'hotelParticulier':
        this.bien.type = ['Hôtel particulier']
        break
      default:
        this.bien.type = this.$options.filters.capitalize(this.typeSearched)
    }
    this.loadBiens()

    await this.getFocus('Tolus Immo', 'Achat')
      .then(res => {
        this.focus = res
          this.pinkHeaderMessage.titre = this.focus.cartouche ? this.focus.cartouche.titre : ''
          this.pinkHeaderMessage.sousTitre = this.focus.cartouche ? this.focus.cartouche.texte : ''
      })
  }
}
