//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// @ is an alias to /src

import DataApi from '../../dependencies/index'
import moment from 'moment'

export default {
  name: 'EtudePatrimonialeForm',
  components: {
    OneToManyFormInput: () => import('../../components/OneToManyFormInput')
  },
  data () {
    return {
      submitResponse: '',
      currentStep: 1,
      model: {
        'cgf_PERP__Personne_estPrincipal::d_situationFamiliale': '',
        'cgf_PERP__Personne_estPrincipal::d_prenom': '',
        'cgf_PERP__Personne_estPrincipal::d_nom': '',
        'cgf_perp_LIEC__Lien_estCouple::d_date': '',
        'cgf_PERP__Personne_estPrincipal::d_naissance': '',
        'cgf_perp_CTCA__Contact_estAdresse::d_chaineDeCaracteres': '',
        'cgf_perp_CTCM__Contact_estMail::d_chaineDeCaracteres': '',
        'cgf_perp_liec_PER__Personne::d_prenom': '',
        'cgf_perp_liec_PER__Personne::d_nom': '',
        'cgf_perp_liec_PER__Personne::d_naissance': '',
        d_liberalites: '',
        'cgf_perp_LIEC__Lien_estCouple::b_donationEpoux': '',
        'cgf_perp_LIEC__Lien_estCouple::d_regimeMatrimonial': '',
        'cgf_perp_LIEC__Lien_estCouple::d_avantagesMatrimoniaux': '',
        'cgf_perp_LIEC__Lien_estCouple::d_questionsComplementaires': '',
        d_commentaireEpargne: '',
        d_commentaireProduitAssurance: '',
        d_commentaireBiensPro: '',
        d_commentaireBiensRapport: '',
        d_commentaireBiensUsage: '',
        d_commentaireBudget: '',
        d_commentaireParticulariteFiscale: '',
        d_commentaireRetraite: '',
        d_objectifAutre: '',
        d_objectifInvestirImmobilier: '',
        d_objectifOptimiserRentabilite: '',
        d_objectifRetraite: '',
        d_objectifRevenus: '',
        d_objectifTransmettreBiens: '',
        b_objectifRevenus: '',
        b_objectifOptimiserRentabilite: '',
        b_objectifInvestirImmobilier: '',
        b_objectifAssuranceDeces: '',
        b_objectifTransmettreBiens: '',
        b_objectifRetraite: '',
        b_objectifAutre: '',
        d_objectifAssuranceDeces: '',
        'cgf_perp_CTCF__Contact_estTelFixe::d_chaineDeCaracteres': '',
        'cgf_perp_CTCP__Contact_estTelMobile::d_chaineDeCaracteres': '',
        'b_etudePatrimoniale': 1
      },
      portalData: {
        cgf_perp_LIE__Lien: [],
        cgf_perp_DETU__Detenir_estUsage: [],
        cgf_perp_DETR__Detenir_estRapport: [],
        cgf_perp_DETP__Detenir_estPro: [],
        cgf_perp_scr_PRDA__Produit_estAssurance: [],
        cgf_perp_SCR__Souscrire: [],
        cgf_perp_scr_PRDPS__Produit_estPassif: [],
        cgf_perp_VLRR__Valeur_estRevenu: [],
        cgf_perp_VLRC__Valeur_estCharge: []

      },
      steps: [
        {
          number: 1,
          groups: [
            {
              label: 'Vous',
              fields: [
                {
                  key: 'cgf_PERP__Personne_estPrincipal::d_prenom',
                  label: 'Prenom',
                  type: 'text',
                  required: true,
                  value: ''
                }, {
                  key: 'cgf_PERP__Personne_estPrincipal::d_nom',
                  label: 'Nom',
                  type: 'text',
                  required: true
                }, {
                  key: 'cgf_PERP__Personne_estPrincipal::d_naissance',
                  label: 'Date de naissance',
                  type: 'date',
                  required: true
                }, {
                  key: 'cgf_PERP__Personne_estPrincipal::d_situationFamiliale',
                  label: 'Situation Familiale',
                  type: 'select',
                  required: true,
                  options: [
                    'Marié', 'PACS', 'Célibataire', 'Divorcé', 'Veuf', 'Union Libre'
                  ]
                }, {
                  key: 'cgf_perp_LIEC__Lien_estCouple::d_date',
                  label: 'Date de Mariage ou PACS',
                  type: 'date',
                  required: true,
                  condition: ['d_situationFamiliale', '==', '"Marié" || "PACS"']
                }, {
                  key: 'cgf_perp_CTCF__Contact_estTelFixe::d_chaineDeCaracteres',
                  label: 'Tel Fixe',
                  type: 'tel',
                  required: true,
                  subtext: 'Au moins un numéro requis'
                }, {
                  key: 'cgf_perp_CTCP__Contact_estTelMobile::d_chaineDeCaracteres',
                  label: 'Tel Mobile',
                  type: 'tel',
                  required: true,
                  subtext: 'Au moins un numéro requis'
                }, {
                  key: 'cgf_perp_CTCA__Contact_estAdresse::d_chaineDeCaracteres',
                  label: 'Adresse',
                  type: 'text',
                  required: true
                }, {
                  key: 'cgf_perp_CTCM__Contact_estMail::d_chaineDeCaracteres',
                  label: 'Email',
                  type: 'email',
                  required: true
                }
              ]
            },
            {
              label: 'Votre conjoint',
              condition: true,
              fields: [
                {
                  key: 'cgf_perp_liec_PER__Personne::d_prenom',
                  label: 'Prenom',
                  type: 'text',
                  required: true,
                  value: ''
                }, {
                  key: 'cgf_perp_liec_PER__Personne::d_nom',
                  label: 'Nom',
                  type: 'text',
                  required: true
                }, {
                  key: 'cgf_perp_liec_PER__Personne::d_naissance',
                  label: 'Date de naissance',
                  type: 'date',
                  required: true
                }
              ]
            },
            {
              label: 'Dispositions',
              condition: true,
              fields: [
                {
                  key: 'cgf_perp_LIEC__Lien_estCouple::d_regimeMatrimonial',
                  label: 'Régimes matrimonial',
                  type: 'select',
                  options: [
                    'Cté réduite aux acquêts',
                    'Cté universelle',
                    'Séparation de biens',
                    'Cté de meuble et d\'acquêts',
                    'Participation aux acquêts'
                  ],
                  required: true
                }, {
                  key: 'cgf_perp_LIEC__Lien_estCouple::b_donationEpoux',
                  label: 'Donation entre époux',
                  type: 'select',
                  options: ['Oui', 'Non'],
                  required: true
                }, {
                  key: 'cgf_perp_LIEC__Lien_estCouple::d_avantagesMatrimoniaux',
                  label: 'Avantages matrimoniaux',
                  type: 'text',
                  required: true
                }, {
                  key: 'cgf_perp_LIEC__Lien_estCouple::d_questionsComplementaires',
                  label: 'Questions complémentaires',
                  type: 'text'
                }
              ]
            },
            {
              label: 'Vos proches',
              fields: [
                {
                  key: 'cgf_perp_LIE__Lien',
                  type: 'OneToManyFormInput',
                  headers: [
                    {
                      name: 'Lien de parenté',
                      key: 'cgf_perp_LIE__Lien::d_type',
                      type: 'select',
                      options: [
                        'Soeur', 'Frère', 'Parent', 'Enfant', 'Petit-enfant'
                      ]
                    },
                    {
                      name: 'Prénom',
                      type: 'text',
                      key: 'cgf_perp_lie_PER__Personne::d_prenom'
                    },
                    {
                      name: 'Nom',
                      type: 'text',
                      key: 'cgf_perp_lie_PER__Personne::d_nom'
                    },
                    {
                      name: 'Date de naissance',
                      type: 'date',
                      key: 'cgf_perp_lie_PER__Personne::d_naissance'
                    },
                    {
                      name: 'A charge fiscalement',
                      type: 'select',
                      options: [
                        'Oui', 'Non'
                      ],
                      key: 'cgf_perp_lie_PER__Personne::d_aChargeFiscalement'
                    },
                    {
                      name: 'Particularité',
                      type: 'text',
                      key: 'cgf_perp_lie_PER__Personne::d_particularite'
                    }
                  ]
                }
              ]
            },
            {
              label: 'Les libéralités consenties',
              fields: [
                {
                  key: 'd_liberalites',
                  label: 'Précisez les caractéristiques des donations et testaments : date, bénéficiaires, biens concernés, type de libéralités, etc..',
                  type: 'textarea',
                  topLabel: true
                }
              ]
            }
          ]
        },
        {
          number: 2,
          groups: [
            {
              label: 'Votre patrimoine',
              fields: [
                {
                  key: 'cgf_perp_DETU__Detenir_estUsage',
                  label: 'Biens d\'usage',
                  type: 'OneToManyFormInput',
                  headers: [
                    {
                      name: 'Forme de détention',
                      type: 'select',
                      key: 'cgf_perp_DETU__Detenir_estUsage::d_forme',
                      options: [
                        'Pleine propriété propre',
                        'Pleine propriété communauté',
                        'Pleine propriété indivision',
                        'Usufruit',
                        'Nue propriété',
                        'SCI',
                        'SAS',
                        'SARL',
                        'Autre'
                      ]
                    },
                    {
                      name: 'Désignation',
                      type: 'select',
                      key: 'cgf_perp_detu_BI1__Bien::d_designation',
                      options: [
                        'Maison/Villa',
                        'Appartement',
                        'Immeuble',
                        'Parking',
                        'Terrain',
                        'Studio',
                        'Local',
                        'Autres'
                      ]
                    },
                    {
                      name: 'Valeur (en €)',
                      type: 'text',
                      key: 'cgf_perp_detu_bi1_VLRP__Valeur_estPatrimoine::d_valeur'
                    },
                    {
                      name: 'Taxe foncière',
                      type: 'text',
                      key: 'cgf_perp_detu_bi1_VLRP__Valeur_estTaxeFonciere::d_valeur'
                    },
                    {
                      name: 'Crédits en cours',
                      type: 'text',
                      key: 'cgf_perp_detu_bi1_VLRD__Valeur_estCredit::d_valeur'
                    },
                    {
                      name: 'Durée',
                      type: 'text',
                      key: 'cgf_perp_detu_bi1_VLRD__Valeur_estCredit::d_duree'
                    },
                    {
                      name: 'Taux',
                      type: 'text',
                      key: 'cgf_perp_detu_bi1_VLRC__Valeur_estCharge::d_taux'
                    },
                    {
                      name: 'Notes',
                      type: 'text',
                      key: 'cgf_perp_detu_bi1_VLRC__Valeur_estCharge::d_particularite'
                    }
                  ]
                },
                {
                  key: 'CGF__Cgform::d_commentaireBiensUsage',
                  label: 'Commentaire',
                  type: 'text'
                },
                {
                  key: 'cgf_perp_DETR__Detenir_estRapport',
                  label: 'Immobilier de rapport',
                  type: 'OneToManyFormInput',
                  headers: [
                    {
                      name: 'Forme de détention',
                      type: 'select',
                      key: 'cgf_perp_DETR__Detenir_estRapport::d_forme',
                      options: [
                        'Pleine propriété propre',
                        'Pleine propriété communauté',
                        'Pleine propriété indivision',
                        'Usufruit',
                        'Nue propriété',
                        'SCI',
                        'SAS',
                        'SARL',
                        'Autre'
                      ]
                    },
                    {
                      name: 'Désignation',
                      type: 'select',
                      key: 'cgf_perp_detr_BI1__Bien::d_designation',
                      options: [
                        'Maison/Villa',
                        'Appartement',
                        'Immeuble',
                        'Parking',
                        'Terrain',
                        'Studio',
                        'Local',
                        'Autres'
                      ]
                    },
                    {
                      name: 'Valeur (en €)',
                      type: 'text',
                      key: 'cgf_perp_detr_bi1_VLRP__Valeur_estPatrimoine::d_valeur'
                    },
                    {
                      name: 'Taxe foncière',
                      type: 'text',
                      key: 'cgf_perp_detr_bi1_VLRP__Valeur_estTaxeFonciere::d_valeur'
                    },
                    {
                      name: 'Revenus et fiscalité',
                      type: 'text',
                      key: 'cgf_perp_detr_bi1_VLRR__Valeur_estRevenu::d_valeur'
                    },
                    {
                      name: 'Crédits en cours, Capital emprunté ou restant dû',
                      type: 'text',
                      key: 'cgf_perp_detr_bi1_VLRD__Valeur_estCredit::d_valeur'
                    },
                    {
                      name: 'Durée',
                      type: 'text',
                      key: 'cgf_perp_detr_bi1_VLRD__Valeur_estCredit::d_duree'
                    },
                    {
                      name: 'Taux',
                      type: 'text',
                      key: 'cgf_perp_detr_bi1_VLRD__Valeur_estCredit::d_taux'
                    },
                    {
                      name: 'Notes',
                      type: 'text',
                      key: 'cgf_perp_detr_bi1_VLRD__Valeur_estCredit::d_particularite'
                    }
                  ]
                },
                {
                  key: 'CGF__Cgform::d_commentaireBiensRapport',
                  label: 'Commentaire',
                  type: 'text'
                },
                {
                  key: 'cgf_perp_DETP__Detenir_estPro',
                  label: 'Biens professionnels',
                  type: 'OneToManyFormInput',
                  headers: [
                    {
                      name: 'Forme de détention',
                      type: 'select',
                      key: 'cgf_perp_DETP__Detenir_estPro::d_forme',
                      options: [
                        'Pleine propriété propre',
                        'Pleine propriété communauté',
                        'Pleine propriété indivision',
                        'Usufruit',
                        'Nue propriété',
                        'SCI',
                        'SAS',
                        'SARL',
                        'Autre'
                      ]
                    },
                    {
                      name: 'Désignation',
                      type: 'select',
                      key: 'cgf_perp_detp_BI1__Bien::d_designation',
                      options: [
                        'Fond de commerce',
                        'Parts de société',
                        'Autres'
                      ]
                    },
                    {
                      name: 'Valeur (en €)',
                      type: 'text',
                      key: 'cgf_perp_detp_bi1_VLRP__Valeur_estPatrimoine::d_valeur'
                    },
                    {
                      name: 'Charges associées',
                      type: 'text',
                      key: 'cgf_perp_detp_bi1_VLRC__Valeur_estCharge::d_valeur'
                    },
                    {
                      name: 'Particularités',
                      type: 'text',
                      key: 'cgf_perp_detp_BI1__Bien::d_particularite'
                    }
                  ]
                },
                {
                  key: 'CGF__Cgform::d_commentaireBiensPro',
                  label: 'Commentaire',
                  type: 'text'
                },
                {
                  key: 'cgf_perp_scr_PRDA__Produit_estAssurance',
                  label: 'Assurances',
                  type: 'OneToManyFormInput',
                  headers: [
                    {
                      name: 'Désignation',
                      type: 'text',
                      key: 'cgf_perp_scr_PRDA__Produit_estAssurance::d_designation'
                    },
                    {
                      name: 'Valeur de rachat',
                      type: 'text',
                      key: 'cgf_perp_scr_prda_VLR__Valeur::d_valeur'
                    },
                    {
                      name: 'Date de souscriptions',
                      type: 'date',
                      key: 'cgf_perp_scr_PRDA__Produit_estAssurance::d_date'
                    },
                    {
                      name: 'Personne assurée prénom',
                      type: 'text',
                      key: 'cgf_perp_scr_prda_PER__Personne::d_prenom'
                    },
                    {
                      name: 'Personne assurée nom',
                      type: 'text',
                      key: 'cgf_perp_scr_prda_PER__Personne::d_nom'
                    },
                    {
                      name: 'Bénéficiaire',
                      type: 'text',
                      key: 'cgf_perp_scr_PRDA__Produit_estAssurance::d_beneficiaires'
                    },
                    {
                      name: 'Particularités',
                      type: 'text',
                      key: 'cgf_perp_scr_PRDA__Produit_estAssurance::d_particularite'
                    }
                  ]
                },
                {
                  key: 'CGF__Cgform::d_commentaireProduitAssurance',
                  label: 'Commentaire',
                  type: 'text'
                },
                {
                  key: 'cgf_perp_SCR__Souscrire',
                  label: 'Epargne et dépôt à moyen et long terme',
                  type: 'OneToManyFormInput',
                  headers: [
                    {
                      name: 'Forme de détention',
                      type: 'select',
                      key: 'cgf_perp_SCR__Souscrire::d_forme',
                      options: [
                        'Pleine propriété propre',
                        'Pleine propriété communauté',
                        'Pleine propriété indivision',
                        'Usufruit',
                        'Nue propriété',
                        'SCI',
                        'SAS',
                        'SARL',
                        'Autre'
                      ]
                    },
                    {
                      name: 'Désignation',
                      type: 'text',
                      key: 'cgf_perp_scr_PRDE__Produit_estEpargne::d_designation'
                    },
                    {
                      name: 'Valeur (en €)',
                      type: 'text',
                      key: 'cgf_perp_scr_prde_VLR__Valeur::d_valeur'
                    },
                    {
                      name: 'Date d\'ouverture',
                      type: 'date',
                      key: 'cgf_perp_scr_PRDE__Produit_estEpargne::d_date'
                    },
                    {
                      name: 'Versement régulier annuel moyen',
                      type: 'text',
                      key: 'cgf_perp_scr_PRDE__Produit_estEpargne::d_epargneAssociee'
                    },
                    {
                      name: 'Particularités',
                      type: 'text',
                      key: 'cgf_perp_scr_PRDE__Produit_estEpargne::d_particularite'
                    }
                  ]
                },
                {
                  key: 'CGF__Cgform::d_commentaireEpargne',
                  label: 'Commentaire',
                  type: 'text'
                }
              ]
            }
          ]
        },
        {
          number: 3,
          groups: [
            {
              label: 'Vos passifs',
              fields: [
                {
                  key: 'cgf_perp_scr_PRDPS__Produit_estPassif',
                  type: 'OneToManyFormInput',
                  headers: [
                    {
                      name: 'Désignation',
                      type: 'text',
                      key: 'cgf_perp_scr_PRDPS__Produit_estPassif::d_designation'
                    },
                    {
                      name: 'Capital restant dû',
                      type: 'text',
                      key: 'cgf_perp_scr_prdps_VLR__Valeur::d_valeur'
                    },
                    {
                      name: 'Date fin prêt',
                      type: 'text',
                      key: 'cgf_perp_scr_prdps_VLR__Valeur::d_fin'
                    },
                    {
                      name: 'Taux',
                      type: 'text',
                      key: 'cgf_perp_scr_prdps_VLR__Valeur::d_taux'
                    },
                    {
                      name: 'Mesualités hors assurance',
                      type: 'text',
                      key: 'cgf_perp_scr_prdps_VLRM__Valeur_estMensualite::d_valeur'
                    },
                    {
                      name: 'Particularités',
                      type: 'text',
                      key: 'cgf_perp_scr_PRDPS__Produit_estPassif::d_particularite'
                    }
                  ]
                }
              ]
            },
            {
              label: 'Votre budget',
              fields: [
                {
                  key: 'cgf_perp_VLRR__Valeur_estRevenu',
                  label: 'Revenus',
                  type: 'OneToManyFormInput',
                  headers: [
                    {
                      name: 'Désignation',
                      type: 'select',
                      key: 'cgf_perp_VLRR__Valeur_estRevenu::d_designation',
                      options: [
                        'Solaire',
                        'Loyer',
                        'BIC',
                        'BNC',
                        'RCM',
                        'Retraite',
                        'Pension divorce',
                        'Pension invalidité',
                        'Pension handicap',
                        'Dividendes',
                        'CAF',
                        'Indemnités chômage',
                        'Autres'
                      ]
                    },
                    {
                      name: 'Montant annuel',
                      type: 'text',
                      key: 'cgf_perp_VLRR__Valeur_estRevenu::d_valeur'
                    },
                    {
                      name: 'Notes',
                      type: 'text',
                      key: 'cgf_perp_VLRR__Valeur_estRevenu::d_particularite'
                    }
                  ]
                },
                {
                  key: 'cgf_perp_VLRC__Valeur_estCharge',
                  label: 'Charges',
                  type: 'OneToManyFormInput',
                  headers: [
                    {
                      name: 'Désignation',
                      type: 'text',
                      key: 'cgf_perp_VLRC__Valeur_estCharge::d_designation'
                    },
                    {
                      name: 'Montant annuel',
                      type: 'text',
                      key: 'cgf_perp_VLRC__Valeur_estCharge::d_valeur'
                    },
                    {
                      name: 'Notes',
                      type: 'text',
                      key: 'cgf_perp_VLRC__Valeur_estCharge::d_particularite'
                    }
                  ]
                },
                {
                  key: 'CGF__Cgform::d_commentaireBudget',
                  label: 'Question complémentaire: quelle est votre capacité d\'épargne mensuelle ?',
                  type: 'text',
                  topLabel: true
                }
              ]
            },
            {
              label: 'Particularités fiscales (plus-values, déficits non encore imputés, revenus perçus à l’étranger, etc.)',
              fields: [
                {
                  type: 'text',
                  key: 'CGF__Cgform::d_commentaireParticulariteFiscale'
                }
              ]
            },
            {
              label: 'Retraite',
              fields: [
                {
                  label: 'Du',
                  type: 'date',
                  key: 'cgf_perp_ACT__Activite::d_dateDebut'
                },
                {
                  label: 'au',
                  type: 'date',
                  key: 'cgf_perp_ACT__Activite::d_dateFin'
                },
                {
                  label: 'statut',
                  type: 'text',
                  key: 'cgf_perp_ACT__Activite::d_statut'
                },
                {
                  label: 'revenu brut',
                  type: 'text',
                  key: 'cgf_perp_act_VLRR__Valeur_estRevenu::d_valeur'
                }
              ]
            },
            {
              label: 'Prévoyance',
              fields: [
                {
                  label: 'Du',
                  type: 'date',
                  key: 'cgf_perp_liec_per_ACT__Activite::d_dateDebut'
                },
                {
                  label: 'au',
                  type: 'date',
                  key: 'cgf_perp_liec_per_ACT__Activite::d_dateFin'
                },
                {
                  label: 'statut',
                  type: 'text',
                  key: 'cgf_perp_liec_per_ACT__Activite::d_statut'
                },
                {
                  label: 'revenu brut',
                  type: 'text',
                  key: 'cgf_perp_liec_per_act_VLRR__Valeur_estRevenu::d_valeur'
                },
                {
                  label: 'Questions complémentaires : avez vous des contrats Entreprise de retraite (Art. 39, Art 82, …) ou de prévoyance (décès, invalidité, incapacité dans votre entreprise) ? Connaissez vous vos droits en matière d\'Indemnités de Fin de Carrière ?',
                  type: 'text',
                  key: 'CGF__Cgform::d_commentaireRetraite',
                  topLabel: true
                }
              ]
            }
          ]
        },
        {
          number: 4,
          groups: [
            {
              label: 'Vos objectifs',
              fields: [
                {
                  label: 'Obtenir des revenus complémentaires (quel montant, quelle durée, quelle périodicité ?)',
                  type: 'checkbox',
                  key: 'CGF__Cgform::b_objectifRevenus',
                  topLabel: true
                },
                {
                  type: 'text',
                  key: 'CGF__Cgform::d_objectifRevenus'
                },
                {
                  label: 'Optimiser la rentabilité de vos placements financiers (quelle est votre sensibilité au risque ?) ',
                  type: 'checkbox',
                  key: 'CGF__Cgform::b_objectifOptimiserRentabilite',
                  topLabel: true
                },
                {
                  type: 'text',
                  key: 'CGF__Cgform::d_objectifOptimiserRentabilite'
                },
                {
                  label: 'Investir dans l\'immobilier (quel apport personnel ?) :',
                  type: 'checkbox',
                  key: 'CGF__Cgform::b_objectifInvestirImmobilier',
                  topLabel: true
                },
                {
                  type: 'text',
                  key: 'CGF__Cgform::d_objectifInvestirImmobilier'
                },
                {
                  label: 'Protéger le conjoint ou les proches contre les conséquences d’un décès (qui protéger, quelles orientations privilégier ?) :',
                  type: 'checkbox',
                  key: 'CGF__Cgform::b_objectifAssuranceDeces',
                  topLabel: true
                },
                {
                  type: 'text',
                  key: 'CGF__Cgform::d_objectifAssuranceDeces'
                },
                {
                  label: 'Transmettre votre patrimoine ou votre entreprise (qui avantager, sous quel horizon ?) :',
                  type: 'checkbox',
                  key: 'CGF__Cgform::b_objectifTransmettreBiens',
                  topLabel: true
                },
                {
                  type: 'text',
                  key: 'CGF__Cgform::d_objectifTransmettreBiens'
                },
                {
                  label: 'Préparer votre retraite :',
                  type: 'checkbox',
                  key: 'CGF__Cgform::b_objectifRetraite',
                  topLabel: true
                },
                {
                  type: 'text',
                  key: 'CGF__Cgform::d_objectifRetraite'
                },
                {
                  label: 'Autre : ',
                  type: 'checkbox',
                  key: 'CGF__Cgform::b_objectifAutre',
                  topLabel: true
                },
                {
                  type: 'text',
                  key: 'CGF__Cgform::d_objectifAutre'
                }
              ]
            }
          ]
        }
      ]
    }
  },
  methods: {
    changeFormTab (tabNumber, event) {
      if (this.validateForm()) {
        this.currentStep = tabNumber
      }
    },
    validateForm () {
      const currentTabInputs = document.querySelectorAll('input')
      if (this.currentStep === 1) {
        this.isPhoneNumberRequired()
      }
      let isValid = true
      for (const element of currentTabInputs) {
        element.classList.add('submitted')
        if (!element.reportValidity()) {
          isValid = false
        }
        if (element.type === 'checkbox' && this.model[element.id]) {
          this.model[element.id] = Number(element.checked)
        }
      }
      return isValid
    },
    async sendForm () {
      if (this.validateForm()) {
        for (const key in this.portalData) {
          if (this.portalData[key].length === 0) {
            delete this.portalData[key]
          }
        }
        this.changeDateFormat()
        this.$getFormToken().then(res => {
          const options = {
            apiUrl: this.$apiUrl,
            databaseName: this.$formDatabaseName,
            token: res
          }
          this.formDataApi = new DataApi(options)
          this.formDataApi.createRecord(
            'CGF_Cgform_etudePatrimoniale',
            this.model, 
            [{
              name: 'validerFormulaire',
              type: 'postrequest'
            }], 
            this.portalData
          )
          .then((response) => {
            this.submitResponse = response
          })
          .catch((error) => {
            console.log(error)
            console.log(Object.getOwnPropertyNames(error))
            console.log(error.message)
            alert('Erreur : ' + error.data.messages[0].message)
          })
        })
      }
    },
    isPhoneNumberRequired () {
      if (this.model['cgf_perp_CTCF__Contact_estTelFixe::d_chaineDeCaracteres'] !== '' || this.model['cgf_perp_CTCP__Contact_estTelMobile::d_chaineDeCaracteres'] !== '') {
        document.getElementById('cgf_perp_CTCF__Contact_estTelFixe::d_chaineDeCaracteres').required = false
        document.getElementById('cgf_perp_CTCP__Contact_estTelMobile::d_chaineDeCaracteres').required = false
      } else {
        document.getElementById('cgf_perp_CTCF__Contact_estTelFixe::d_chaineDeCaracteres').required = true
        document.getElementById('cgf_perp_CTCP__Contact_estTelMobile::d_chaineDeCaracteres').required = true
      }
    },
    handleInput ($event) {
      console.log($event)
    },
    changeDateFormat () {
      const regex = /\d{4}-\d{2}-\d{2}/
      for (const key in this.model) {
        if (regex.test(this.model[key])) {
          this.model[key] = moment(this.model[key], 'YYYY-MM-DD').format('MM/DD/YYYY')
        }
      }
      for (const portalKey in this.portalData) {
        for (const index in this.portalData[portalKey]) {
          for (const key in this.portalData[portalKey][index]) {
            if (regex.test(this.portalData[portalKey][index][key])) {
              this.portalData[portalKey][index][key] = moment(this.portalData[portalKey][index][key], 'YYYY-MM-DD').format('MM/DD/YYYY')
            }
          }
        }
      }
    }
  },
  computed: {
    isCouple: function (val) {
      if (this.model['cgf_PERP__Personne_estPrincipal::d_situationFamiliale'] === 'Marié' || this.model['cgf_PERP__Personne_estPrincipal::d_situationFamiliale'] === 'PACS') {
        return 1
      }
      return 0
    }
  }
}
