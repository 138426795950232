//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DataApi from 'fm-data-api'
import AdSearchbar from './AdSearchbar.vue'
import AdPreviewCard from './AdPreviewCard.vue'
import Vue from 'vue'
import Element from 'element-ui'

Vue.use(Element)

export default {
  name: 'AdList',
  components: {
    AdSearchbar,
    AdPreviewCard
  },
  data() {
    return {
      ads: {},
      adsFound: true,
      currentPage: 1,
      adsNumber: 0,
      adsLimit: 9,
      adSearchModel: {
        type: null,
        roomCount: null,
        surface: null,
        coordinates: null,
        distance: '20',
        budget: null
      }
    }
  },
  methods: {
    async getAds(adSearchModel) {
      const options = {
        apiUrl: this.$apiUrl,
        databaseName: this.$databaseName,
        token: await this.$getMainToken()
      }
      this.dataApi = new DataApi(options)

      let query = this.buildGetAdQuery(adSearchModel)

      if (adSearchModel.coordinates !== null && adSearchModel.coordinates) {
        let coordinatesArray = adSearchModel.coordinates.split('@')
        await this.dataApi.setGlobalFields('', {
          "ANN__Annonce::g_latLieu": coordinatesArray[0],
          "ANN__Annonce::g_lonLieu": coordinatesArray[1],
          "ANN__Annonce::g_rayonLieu": adSearchModel.distance
        })
        if (query && query.length) {
          query[0].fields.push({
            fieldname: 'd_dansLaZoneDeRecherche',
            fieldvalue: 1
          })
        } else {
          query = []
          query.push({
            fields: [{
              fieldname: 'd_dansLaZoneDeRecherche',
              fieldvalue: 1
            }]
          })
        }
      }

      const sort = [{
        fieldName: 'd_dateAnnonce',
        sortOrder: 'descend'
      }]
      if (!query.length) {
        this.dataApi.getRecords('W_Annonce', sort, this.offset, '9', [], [], true)
          .then((res) => {
            this.insertAds(res)
          })
          .catch((error) => {
            console.log(error)
          })
      } else {
        this.dataApi.findRecords('W_Annonce', query, sort, this.offset, '9', [], [], true)
          .then((res) => {
            this.insertAds(res)
          })
          .catch((error) => {
            console.log(error)
          })
      }
    },
    insertAds(ads) {
      if (!('dataInfo' in ads && 'foundCount' in ads.dataInfo)) {
        this.adsFound = false
        console.log('error: ' + ads)
        return
      }
      this.adsNumber = ads.dataInfo && ads.dataInfo.foundCount
      if (this.adsNumber === 0) {
        this.adsFound = false
        return
      }
      const adsToInsert = {}
      for (let ad of ads.data) {
        ad.fieldData.recordId = ad.recordId
        adsToInsert[ad.recordId] = ad.fieldData
      }
      this.adsFound = true
      this.ads = adsToInsert
    },
    onAdSearchSubmit(adSearchModel) {
      this.getAds(adSearchModel)
    },
    buildGetAdQuery(adSearchModel) {
      let fields = []
      let simpleFields = {}
      if (adSearchModel.surface != null) { simpleFields.d_surface = adSearchModel.surface.join('...') }
      if (adSearchModel.budget != null) { simpleFields.d_prix = adSearchModel.budget.join('...') }
      if (adSearchModel.type && adSearchModel.type.length > 0) {
        for (let type of adSearchModel.type) {
          if (adSearchModel.roomCount && adSearchModel.roomCount.length > 0) {
            for (let room of adSearchModel.roomCount) {
              fields.push({
                d_nbrPieces: room,
                d_type: type,
                ...simpleFields
              })
            }
          } else {
            fields.push({
              d_type: type,
              ...simpleFields
            })
          }
        }
      } else if (adSearchModel.roomCount && adSearchModel.roomCount > 0) {
        for (let room of adSearchModel.roomCount) {
          fields.push({
            d_nbrPieces: room,
            ...simpleFields
          })
        }
      } else if (Object.keys(simpleFields).length > 0) {
        fields.push({ ...simpleFields })
      }

      if (fields.length === 0) {
        return false
      } else {
        let query = []
        for (let field of fields) {
          let arrayField = []
          for (let key in field) {
            arrayField.push({
              fieldname: key,
              fieldvalue: field[key]
            })
          }
          query.push({
            fields: arrayField
          })
        }
        return query
      }
    }
  },
  computed: {
    offset: function () {
      const offset = ((this.currentPage - 1) * this.adsLimit).toString()
      return offset
    }
  },
  watch: {
    currentPage: function (val, oldVal) {
      this.getAds(this.adSearchModel)
    }
  },
  async beforeMount() {
    if (this.$route.params.adSearchModel) {
      this.adSearchModel = this.$route.params.adSearchModel
    }
    this.getAds(this.adSearchModel)
  }
}
