//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DataApi from 'fm-data-api'

export default {
  name: 'SiteLink',
  data: () => {
    return {
      societes: null
    }
  },
  props: {
    siteName: String
  },
  async beforeMount() {
      const options = {
          apiUrl: this.$apiUrl,
          databaseName: this.$databaseName,
          token: await this.$getMainToken()
      }
      this.dataApi = new DataApi(options)
      await this.dataApi.getRecords('W_Societe', '', '', '')
      .then((response) => {
        this.societes = {}
        for (let societe of response) {
          societe = societe.fieldData
          this.societes[societe.d_codeSociete] = societe
        }
      })
    }
}
