//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SiteLink from '../Components/SiteLink.vue'
import { getFocus } from '../../mixins/getFocus'

export default {
    name: 'PatrimoineHome',
    components: {
        SiteLink
    },
    mixins: [getFocus],
    data() {
        return {
            focus: {},
            focusEpargne: {},
            focusIngenierie: {}
        }
    },
    async beforeMount() {
        this.getFocus('gp', 'Accueil')
        .then(res => {
            this.focus = res
        })
        this.getFocus('gp', 'Épargne & Retraite')
        .then(res => {
            this.focusEpargne = res
        })
        this.getFocus('gp', 'Ingénierie & conseils patrimoniaux')
        .then(res => {
            this.focusIngenierie = res
        })
    }
}
