//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// @ is an alias to /src

import { getFocus } from '../../mixins/getFocus'

export default {
  name: 'EpargneRetraite',
  mixins: [getFocus],
  components: {
    ColorPointsHeader: () => import('../../components/ColorPointsHeader')
  },
  data () {
    return {
      greenHeaderMessage: {
        titre: '',
        sousTitre: ''
      },
      temoignages: {},
      focus: []
    }
  },
  async beforeMount () {
    await this.getFocus('CG Courtage', 'Épargne / Retraite')
      .then(res => {
        this.focus = res
        this.greenHeaderMessage.titre = this.focus.cartouche ? this.focus.cartouche.titre : ''
        this.greenHeaderMessage.sousTitre = this.focus.cartouche ? this.focus.cartouche.texte : ''
      })
  }
}
