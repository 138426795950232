//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SiteLink from '../Components/SiteLink.vue'
import { getFocus } from '../../mixins/getFocus'

export default {
    name: 'CourtageHome',
    components: {
        SiteLink
    },
    mixins: [getFocus],
    data() {
        return {
            focus: {},
            focusAssurance: {},
            focusFinancement: {}
        }
    },
    async beforeMount() {
        await this.getFocus('cg', 'Accueil')
        .then(res => {
            this.focus = res
        })
        await this.getFocus('cg', 'Assurances & Prévoyance')
        .then(res => {
            this.focusAssurance = res
        })
        await this.getFocus('cg', 'Financement Immo & Pro')
        .then(res => {
            this.focusFinancement = res
        })
    }
}
