//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DataApi from 'fm-data-api'
import AdSearchbar from './AdSearchbar.vue'
import AdPreviewCard from './AdPreviewCard.vue'
import SiteLink from '../Components/SiteLink.vue'
import { getFocus } from '../../mixins/getFocus'

export default {
    name: 'ImmoHome',
    components: {
        AdSearchbar,
        AdPreviewCard,
        SiteLink
    },
    mixins: [getFocus],
    data() {
        return {
            ads: {},
            focus: {}
        }
    },
    methods: {
        async getLastAds() {
            const options = {
                apiUrl: this.$apiUrl,
                databaseName: this.$databaseName,
                token: await this.$getMainToken()
            }
            this.dataApi = new DataApi(options)
            const sort = [{
                fieldName: 'd_dateAnnonce',
                sortOrder: 'descend'
            }]
            this.dataApi.getRecords('W_Annonce', sort, '', '3')
                .then((ads) => {
                    console.log('then')
                    const adsToInsert = {}
                    for (let ad of ads) {
                        ad.fieldData.recordId = ad.recordId
                        adsToInsert[ad.recordId] = ad.fieldData
                    }
                    this.ads = adsToInsert
                })
                .catch(err => {
                    console.log('catched')
                    console.log(err)
                })
        },
        onAdSearchSubmit(adSearchModel) {
            this.$router.push({ name: 'AdList', params: { adSearchModel: adSearchModel } })
        }
    },
    async beforeMount() {
        this.getLastAds()
        await this.getFocus('ti', 'Accueil')
        .then(res => {
            this.focus = res
        })
    }
}
