//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DataApi from 'fm-data-api'
import NewsletterBar from '../Components/NewsletterBar.vue'
import SiteNavbar from '../Components/SiteNavbar.vue';
import FacebookIcon from '../Components/FacebookIcon.vue';
import LinkedinIcon from '../Components/LinkedinIcon.vue';
import UserIcon from '../Components/UserIcon.vue';

export default {
    name: 'Immobilier',
    components: {
        SiteNavbar,
        NewsletterBar,
        FacebookIcon,
        LinkedinIcon,
        UserIcon
    },
    data: () => {
        return {
            menuOpen: false,
            activeSociete: {}
        }
    },
    watch:{
        $route (to, from){
            this.menuOpen = false;
        }
    },
    async beforeMount() {
        const options = {
            apiUrl: this.$apiUrl,
            databaseName: this.$databaseName,
            token: await this.$getMainToken()
        }
        this.dataApi = new DataApi(options)
        this.dataApi.findRecords('W_Societe', { 'd_codeSociete': 'ti' }, '', '', '')
        .then((response) => {
            this.activeSociete = response[0] ? response[0]['fieldData'] : null
        })
    }
}
