//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DataApi from 'fm-data-api'
import { getFocus } from '../../mixins/getFocus'
import SiteLink from '../Components/SiteLink.vue'

export default {
  name: 'ImmoAgence',
  mixins: [getFocus],
  components: {
    SiteLink
  },
  props: {
    siteCode: String
  },
  data() {
    return {
      collaborateurs: {},
      focus: {},
      siteName: this.siteCode == 'cg' ? 'Courtage' : this.siteCode == 'ti' ? 'Immobilier' : 'Patrimoine'
    }
  },
  methods: {
    async loadCollaborateurs() {
      const options = {
        apiUrl: this.$apiUrl,
        databaseName: this.$databaseName,
        token: await this.$getMainToken()
      }
      this.dataApi = new DataApi(options)
      this.dataApi.findRecords('W_Collaborateur', { 'col_SOC__Societe::d_nomSociete': 'Giordano ' + this.siteName }, '', '', '')
        .then((response) => {
          this.insertCollaborateurs(response)
        })
    },
    insertCollaborateurs(collaborateursList) {
      if (collaborateursList.length > 0) {
        const collaborateursToInsert = {}
        collaborateursList.forEach(function (value, index) {
          const dataToInsert = value.fieldData
          dataToInsert.recordId = value.recordId
          collaborateursToInsert[dataToInsert.ordre] = dataToInsert
        })
        this.collaborateurs = collaborateursToInsert
      } else {
        this.collaborateurs = {}
      }
    }
  },
  created() {
    this.loadCollaborateurs()
  },
  async beforeMount() {
    let pageName = this.siteCode == 'cg' ? 'Équipe' : this.siteCode == 'ti' ? 'Agence' : 'Notre équipe'
    await this.getFocus(this.siteCode, pageName)
      .then(res => {
        this.focus = res
      })
  }
}
