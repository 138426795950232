import 'regenerator-runtime'
import Vue from 'vue'
import removeFMLayout from './plugins/removeFMLayout'
import alertMailParam from './plugins/alertMailParam'
import { registerFilters } from './filter'
import App from './App.vue'
import defaultRouter from './router/index'
import immobilierRouter from './router/immobilier'
import courtageRouter from './router/courtage'
import patrimoineRouter from './router/patrimoine'
import axios from 'axios'
import { Select, Slider } from 'element-ui'
import Toasted from 'vue-toasted';

registerFilters()

const dbAddress = 'fms.cgcourtage.fr/fmi/data' // 'fmp.editomac.net/fmi/data' // fmp.lyje.eu/fmi/data fmp.editomac.net

Vue.config.productionTip = false
Vue.prototype.$apiUrl = 'https://' + dbAddress
Vue.prototype.$databaseName = 'Tampon'
Vue.prototype.$formDatabaseName = 'cgform'

Vue.prototype.$getMainToken = function () {
  return new Promise((resolve, reject) => {
    axios.get('/api/getToken.main', {
      timeout: 5000
    })
    .then(response => {
      resolve(response.data.response.token)
    })
    .catch(error => {
      this.$toasted.show('Problème de communication avec le serveur, merci de réessayer plus tard')
    })
  })
}

Vue.prototype.$getFormToken = function () {
  return new Promise((resolve, reject) => {
    axios.get('/api/getToken.form')
    .then(response => {
      resolve(response.data.response.token)
    })
  })
}

Vue.prototype.$isLogged = false

Vue.use(Select)
Vue.use(Slider)
Vue.use(removeFMLayout)
Vue.use(alertMailParam)
Vue.use(Toasted, {
  position: 'top-center',
  action : {
    text : 'X',
    onClick : (e, toastObject) => {
      toastObject.goAway(0);
    }
  }
})

const host = window.location.host;
const parts = host.split('.');

let router = defaultRouter
var link = document.querySelector("link[rel~='icon']")
if (parts.length < 2) {
  router = defaultRouter;
} else if (parts[1].includes('immobilier')) {
  router = immobilierRouter;
  document.title = 'Giordano Immobilier';
  link.href = require('../public/immofavicon.png')
} else if (parts[1].includes('courtage')) {
  router = courtageRouter;
  document.title = 'Giordano Courtage';
  link.href = require('../public/courtagefavicon.png')
} else if (parts[1].includes('patrimoine')) {
  router = patrimoineRouter;
  document.title = 'Giordano Patrimoine';
  link.href = require('../public/patrimoinefavicon.png')
} else {
  router = defaultRouter;
}

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
