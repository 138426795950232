//
//
//
//
//
//
//
//
//

import DataApi from 'fm-data-api'
import Vue from 'vue'

export default {
  name: 'InfosCGC',
  data () {
    return {
      focusToInsert: []
    }
  },
  components: {
    FocusPreview: () => import('../../components/FocusPreview.vue')
  },
  methods: {
    async getFocus (societe, rubrique) {
      const options = {
        apiUrl: this.$apiUrl,
        databaseName: this.$databaseName,
        token: await this.$getMainToken()
      }
      this.dataApi = new DataApi(options)
      this.dataApi.findRecords('W_Focus', { 'foc_RBQ__Rubrique::d_nomRubrique': rubrique, 'foc_rbq_SOC__Societe::d_nomSociete': societe }, '', '', '4')
        .then((focusList) => {
          this.insertFocus(focusList)
        })
    },
    insertFocus (focusL) {
      const focusList = (focusL === undefined) ? [] : focusL
      if (focusList.length > 0) {
        this.biensEmpty = false
        const focusToInsert = {}
        focusList.forEach(function (value, index) {
          const dataToInsert = Vue.removeLayout(value.fieldData)
          dataToInsert.recordId = value.recordId
          focusToInsert[index] = dataToInsert
        }, this)
        this.focusToInsert = focusToInsert
      } else {
        this.focusToInsert = {}
      }
    }
  },
  async beforeMount () {
    await this.getFocus('CG Courtage', 'Info')
  }
}
