//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getFocus } from '../../mixins/getFocus'
import SiteLink from '../Components/SiteLink.vue'

export default {
  name: 'Financement',
  mixins: [getFocus],
  components: {
    SiteLink
  },
  data() {
    return {
      focus: {}
    }
  },
  async beforeMount() {
    await this.getFocus('cg', 'Assurances & Prévoyance')
      .then(res => {
        this.focus = res
      })
  }
}
